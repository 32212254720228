import React, { useReducer, useState, useEffect, useContext } from 'react'
import TablePage from '../arhiel/pages/TablePage'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../variable';
import ContextCenter from './ContextCenter';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            // console.log( " asddsa", action.data)
            return [...state, action.data]
        case 'delete':
            // console.log(action.data)
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        case 'edit':
            state[action.index] = action.data;

            return state;
        case 'init':

            return action.data
        default:
            return state;
    }
}

const Perusahaan = () => {
    const navigate = useNavigate()
    const [verifikasi, setverifikasi] = useState(false)
    const field = [
        {
            title: 'Nama Lengkap',
            type: 'text',
            name: 'nama',
            require: true
        },
        {
            title: 'User Login',
            type: 'text',
            name: 'username',
            require: true
        },
        {
            title: 'Password Login',
            type: 'password',
            name: 'password',
            require: true
        },
        {
            title: 'Jenis User',
            type: 'select',
            item: [
                {

                    title: "Admin User",
                    value: 0,
                },
                {

                    title: "User Oli",
                    value: 1,
                },
                {

                    title: "User Pajak",
                    value: 2,
                }
            ],
            name: 'level',
            require: true
        }
    ]

    const [data, dispatch] = useReducer(reducer, []);
    const [initLoading, setInitLoading] = useState(true)

    const [stateAuth, dispatchAuth] = useContext(AuthContext)
    useEffect(() => {
        axios.get(SERVER + "/cektoken", { withCredentials: true }).then(res => {
            if (res.data.error === 0) {
                dispatchAuth({
                    type: "LOGIN",
                    payload: res.data.data
                })
                setverifikasi(true)
            } else {
                dispatchAuth({
                    type: "LOGOUT"
                })
                // navigate("/", { replace: true });

            }
        }).catch(error => {
            console.log(error)
            alert("Terjadi Masalah, Silahkan Ulangi Beberapa Saat Lagi")
        })
    }, [])
    const logout = async () => {
        await axios.get(SERVER + '/logout', {withCredentials:true})
        dispatchAuth({
            type: "LOGOUT"
        })
    }
    useEffect(() => {
        if (verifikasi) {

            setInitLoading(true)
            axios.get(SERVER + '/user', { withCredentials: true }).then(res => {

                setInitLoading(false)
                dispatch({ type: 'init', data: res.data })
            }).catch(error => {
                if (error.response.status === 403) {
                    alert("Anda Harus Login Kembali")
                } else {
                    alert("Terjadi Kesalahan Dalam Pengambilan Data, Silahkan Ulangi Beberapa Saat Lagi")
                }
            })

        }
    }, [verifikasi])
    return (
        <ContextCenter loading={!verifikasi}>
            <div className='flex justify-between items-center'>
                <h1 className='mt-2 ml-2 text-lg font-bold text-red-700'>Daftar User</h1>
                <label onClick={logout} className='text-red-700 hover:text-red-300 font-bold cursor-pointer mt-2 mr-2'>Logout</label>
            </div>
            <TablePage
                link="/user"
                data={[data, dispatch]}
                initLoading={initLoading}
                show={10}
                field={field} />
        </ContextCenter>
    )
}

export default Perusahaan
