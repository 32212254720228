import React, {useState, useEffect} from 'react'
import * as FAicons from 'react-icons/fa'
import ModalCover from '../ModalCover';

const ModalSelect = props => {

    const data = props.data;

    const [visible, setVisible] = props.visible
    const [value, setvalue] = useState("")
    
    const filterCustomer = () => {
        const a = data.filter(a => a.title.toLowerCase().includes(value.toLowerCase()))
        return a;
    }
    useEffect(() => {
       setvalue("")
    }, [visible])
    return (
        <ModalCover visible={visible} width="1/3" bg="red-200">
            <input 
                value={value}
                onChange={v => setvalue(v.target.value)}
                className="text-xs mb-1 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari" />

            <ul className="bg-gray-100  p-0 h-72 overflow-y-scroll">
                {filterCustomer().map(item => {
                    return <li className="bg-gray-50 items-center p-2 mb-0.5 flex">
                        <span className=" flex-1 text-xs flex items-center text-gray-700"><FAicons.FaCircle className="mr-1" color="#FF0000" /> {item.title}</span>
                        <button onClick={()=> props.onSelect(item)} className="rounded-sm shadow-sm bg-red-400 hover:bg-red-500 text-gray-50 text-xs py-1 px-2 font-bold">
                            Pilih
                        </button>
                    </li>
                })}
            </ul>
        </ModalCover>
    )
}

export default ModalSelect
