import React from 'react'

const TdDT = props => {
    return (
        <td className={"text-gray-900 whitespace-nowrap text-xs p-2 md:border md:border-grey-500 text-left block md:table-cell md:text-right"}>
            {props.children} 
        </td>
    )
}

export default TdDT
