import React, { useState, useReducer, useEffect } from 'react'
import ArhielLabel from '../../arhiel/components/form/ArhielLabel'
import JudulPage from '../../arhiel/components/JudulPage'
import Context from '../Context'
import ArhielTextBox from '../../arhiel/components/form/ArhielTextBox'
import ArhielDatePicker from '../../arhiel/components/form/ArhielDatePicker'
import ArhielDinamicTable from '../../arhiel/components/form/dinamicTable/ArhielDinamicTable'
import AddBarangPembelian from '../../components/AddBarangPembelian'
import { Rp } from '../../function/Rupiah'
import AddNewDT from '../../arhiel/components/form/dinamicTable/AddNewDT'
import * as FaIcons from "react-icons/fa";
import Confirm from '../../arhiel/components/Confirm'
import Loading from '../../arhiel/components/Loading'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable'
import ArhielSelect from '../../arhiel/components/form/ArhielSelect'


const reducer = (state, action) => {
    switch (action.type) {
        case 'delete':
            console.log(action.data)
            const newstate = state.filter(a => a.idBaris !== action.data)
            return newstate;
        case 'add':
            return [...state, action.data]
        case 'edit':
            state[action.index] = action.data;
            return state;
        case 'clear':
            return []
        default:
            return state;
    }
}

const PembelianOli = () => {

    const [isLoading, setisLoading] = useState(true)
    const [data, dispatch] = useReducer(reducer, []);
    const [tglTransaksi, settglTransaksi] = useState(new Date())
    const [namaPenjual, setnamaPenjual] = useState("")
    const [kota, setkota] = useState("")

    const [isAdd, setisAdd] = useState(false)

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", "Kode Barang", "Nama Barang", "Jumlah Barang", "Harga Satuan", "Jumlah Pembayaran", "PPN 10%", "Total Bayar"
        ]
        const value = [];
        data.map((item, index) => {
            const isivalue = []
            isivalue.push({ value: index + 1, align: 'text-center' })
            isivalue.push({ value: item.kodeBarang, align: 'text-center' })
            isivalue.push({ value: item.namaBarang, align: 'text-left' })
            isivalue.push({ value: item.jumlahBarang, align: 'text-right' })
            isivalue.push({ value: Rp(item.hargaTotal / item.jumlahBarang, false), align: 'text-right' })
            isivalue.push({ value: Rp(item.hargaTotal, false), align: 'text-right' })
            isivalue.push({ value: Rp(item.ppn, false), align: 'text-right' })
            isivalue.push({ value: Rp(item.hargaTotal + item.ppn, false), align: 'text-right' })
            value.push({ key: item.idBaris, value: isivalue })
            return false
        })
        json['value'] = value;
        json['property'] = {

        }
        return json;
    }


    const [addForm, setaddForm] = useState(false)

    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="7" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total Jumlah Bayar</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getjumlah(), true)}</td>
                <td className="text-right p-2 hidden text-sm md:table-cell"></td>
            </tr>
        )
    }
    const getjumlah = () => {
        return data.reduce((a, b) => {
            return a + (b['hargaTotal'] + b['ppn']);
        }, 0);
    }

    const verifikasiData = () => {
        if (namaPenjual === "") {
            alert("Masukan Nama Penjual / Nama Toko Terlebih Dahulu")
        } else if (data.length === 0) {
            alert("Masukan Minimal 1 Data Barang")

        } else if (kota === "") {
            alert("Pilih Kota Terlebih Dahulu")

        } else {
            setconfirmSave(true)
        }
    }

    const [loadingVisible, setloadingVisible] = useState(false)
    const [confirmSave, setconfirmSave] = useState(false)
    const saveHandler = () => {
        const dataBarang = data.map(({ namaBarang, kodeBarang, ...keepAttrs }) => keepAttrs)
        const tgltrx = new Date(Date.UTC(tglTransaksi.getFullYear(), tglTransaksi.getMonth(), tglTransaksi.getDate()));
        const result = {
            tglTransaksi: tgltrx,
            namaPenjual,
            kota,
            dataBarang: dataBarang
        }
        setconfirmSave(false)
        setloadingVisible(true)

        axios.post(SERVER + `/pembelian`, result, {withCredentials:true})
            .then(res => {

                setloadingVisible(false)
                const statusCode = res.status
                if (statusCode === 200) {
                    setnamaPenjual("")
                    dispatch({ type: 'clear' })
                    setkota("")
                    settglTransaksi(new Date())
                    alert("Data Telah Di Simpan")
                }
            })

    }

    const addHandler = () => {
        setaddForm(true)
        setisAdd(true)
    }
    const [confirmVisible, setconfirmVisible] = useState(false)
    const [keySelected, setkeySelected] = useState(0)
    const editHandler = key => {
        // console.log(data)
        setkeySelected(key)
        // console.log(data)
        setisAdd(false)
        setaddForm(true)
    }
    const deleteHandler = key => {
        setconfirmVisible(true)
        setkeySelected(key)
    }
    const yesHadler = () => {

        dispatch({ type: 'delete', data: keySelected })
    }


    

    const [dataBarang, setdataBarang] = useState([])
    useEffect(() => {
        axios.get(SERVER + '/barang', {withCredentials:true}).then(res => {
            setisLoading(false)
            if (res.status === 200) {
                setdataBarang(res.data)
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])
    return (
        <Context loading={isLoading}>

            <AddNewDT visible={[addForm, setaddForm]}>
                <AddBarangPembelian dataBarang={dataBarang} is={[isAdd, keySelected]} visible={[addForm, setaddForm]} data={[data, dispatch]} />
            </AddNewDT>
            <Loading visible={[loadingVisible, setloadingVisible]} />

            <Confirm
                visible={[confirmVisible, setconfirmVisible]}
                title="Hapus Data Barang Ini ?"
                titleyes="Hapus"
                yes={yesHadler} />
            <Confirm
                visible={[confirmSave, setconfirmSave]}
                title="Apakah Data Yang Dimasukan Sudah Benar ?"
                titleyes="Simpan"
                yes={saveHandler} />
            <JudulPage>Input Pembelian</JudulPage>
            <div className="md:flex">
                <div className="md:flex-1 p-2">
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Tanggal Transaksi
                        </ArhielLabel>
                        <ArhielDatePicker value={[tglTransaksi, settglTransaksi]} />
                    </div>
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Nama Penjual
                        </ArhielLabel>
                        <ArhielTextBox type="text" placeholder="Masukan Nama Penjual / Nama Toko" value={namaPenjual} onChange={v => setnamaPenjual(v.target.value)} />
                    </div>
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Kota Tujuan
                        </ArhielLabel>
                        <ArhielSelect value={kota} placeholder="Pilih Kota" onChange={v => setkota(v.target.value)}>
                            <option value={"toli-toli"}>Toli-Toli</option>
                            <option value={"buol"}>Buol</option>
                        </ArhielSelect>
                    </div>
                    
                </div>

            </div>
            <div className="bg-red-200 p-2 rounded-md shadow-md">
                <ArhielDinamicTable
                    data={dataTable()}
                    addForm={[addForm, setaddForm]}
                    state={[data, dispatch]}
                    navrow={true}
                    addHandler={addHandler}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    footer={footerTable()} />
            </div>
            <div className="border-t-2 border-solid border-gray-200 mt-2 p-2 flex justify-end items-center">
                <span className="mr-2 text-sm font-bold text-gray-500 italic">Harap Pastikan Seluruh Data Terisi Dengan Benar</span>
                <button onClick={verifikasiData} className=" bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                    <FaIcons.FaSave className="mr-1" />
                    <span>Simpan</span>
                </button>
            </div>
        </Context>
    )
}

export default PembelianOli
