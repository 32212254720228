import React, { useReducer, useEffect, useState } from 'react'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable';
import Context from '../../Context';
import { useNavigate, useParams } from "react-router-dom";
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable';
import JudulPage from '../../../arhiel/components/JudulPage';
import { ArhielTgl } from '../../../function/ArhielTgl';
import NomorFaktur from '../../../function/NomorFaktur';
import ArhielNavigasi from '../../../arhiel/components/ArhielNavigasi';
import JudulTable from '../../../arhiel/components/JudulTable';

import * as FaIcons from "react-icons/fa";
import { NamaBulan } from '../../../function/ArhielMonth';

import DownloadExcel from '../../../arhiel/components/DownloadExcel';
import PDFTable from '../../../components/PDFTable';
import ArhielPDFView from '../../../arhiel/components/ArhielPDFView';
import ModalWindow from '../../../arhiel/components/ModalWindow';
import DetailPenjualan from '../penjualan/DetailPenjualan';
import DetailPembelian from '../pembelian/DetailPembelian';

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.data
        case 'delete':
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        default:
            return state;
    }
}

const TransaksiStok = props => {
    const params = useParams()
    const [namaKota, setnamaKota] = useState(params.kota)
    const [data, dispatch] = useReducer(reducer, [])
    const [loadingVisible, setloadingVisible] = useState(true)

    const [satuanBarang, setsatuanBarang] = useState("")

    const [typeData, settypeData] = useState("bulanan")
    const [valueData, setvalueData] = useState({ bulan: params.bulan, tahun: params.tahun })

    // useEffect(() => {
    //     setloadingVisible(true)
    //     getData(typeData, valueData)
    // }, [])
    useEffect(() => {
        setloadingVisible(true)
        getData(typeData, valueData)
    }, [params.kota, params.idBarang, params.bulan, params.tahun])

    const handleNavClick = (type, value) => {
        // history.push()
        settypeData(type);
        setvalueData(value);
        navigate("../../" + params.kota + "/" + params.idBarang + "/" + value.bulan + "/" + value.tahun)
    }


    const [judulTable, setjudulTable] = useState("")
    const getData = (type, value) => {
        setloadingVisible(true)
        setsearchText("")
        setpage(1)


        // alert(SERVER + "oli/penjualan/" +link)
        // stok/transaksi/toli-toli/616e0acd3fea44fd17576f64/10/2021
        axios.get(SERVER + "/stok/transaksi/" + params.kota + "/" + params.idBarang + "/" + params.bulan + "/" + params.tahun, { withCredentials: true }).then((res) => {
            console.log(res)
            setjudulTable(<>
                <p className="capitalize">Data Transaksi Oli {params.kota}</p>
                <p className="capitalize">{res.data.detail.kodeBarang} - {res.data.detail.namaBarang}</p>
                <p>Bulan {NamaBulan[value.bulan - 1]} {value.tahun}</p></>)

            setnamaFileExport("Data Transaksi Oli " + params.kota)
            setprintjudulTable("Bulan " + NamaBulan[value.bulan - 1] + " " + value.tahun)
            setloadingVisible(false)
            setsatuanBarang(res.data.detail.satuanBarang)
            dispatch({ type: 'init', data: res.data })
            // console.log(res.data)
        })
    }

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", 'Tanggal', "Nomor Faktur", "Pembelian", "Penjualan", "Stok"
        ]
        const value = [];

        if (data.datas) {

            let jumlah = 0;
            const isivaluet = []
            isivaluet.push({ value: "", align: 'text-center' })
            isivaluet.push({ value: "Stok Bulan Lalu", align: 'text-left' })
            isivaluet.push({ value: "", align: 'text-left' })
            isivaluet.push({ value: data.bulanSebelumnya.masuk + " " + data.detail.satuanBarang, align: 'text-right' })
            isivaluet.push({ value: data.bulanSebelumnya.keluar + " " + data.detail.satuanBarang, align: 'text-right' })
            isivaluet.push({ value: data.bulanSebelumnya.sisa + " " + data.detail.satuanBarang, align: 'text-right' })
            value.push({ key: 0, value: isivaluet })
            jumlah += data.bulanSebelumnya.sisa;

            data.datas.map((item, index) => {

                jumlah = item.type === "penjualan" ? jumlah - item.dataBarang.jumlahBarang : jumlah + item.dataBarang.jumlahBarang;



                const isivalue = []
                isivalue.push({ value: index + 1, align: 'text-center' })
                isivalue.push({ value: ArhielTgl(item.tglTransaksi), align: 'text-left' })
                isivalue.push({ value: item.nomorFaktur ? NomorFaktur(item.nomorFaktur, item.tglTransaksi) : "-", align: 'text-left' })
                isivalue.push({ value: item.type === "pembelian" ? item.dataBarang.jumlahBarang + " " + data.detail.satuanBarang : "-", align: 'text-right' })
                isivalue.push({ value: item.type === "penjualan" ? item.dataBarang.jumlahBarang + " " + data.detail.satuanBarang : "-", align: 'text-right' })
                isivalue.push({ value: jumlah + " " + data.detail.satuanBarang, align: 'text-right' })
                value.push({ key: item._id, value: isivalue })

                return false
            }) /// perbaiki masalah pangiil berulang ulang sehingga itu console log diatas jadi berkali kali
            // settotalPembelian(pembelian)
            // settotalPenjualan(penjualan)
        }

        json['value'] = value;
        json['property'] = {

        }
        return json;
    }
    const getjumlahPembelian = () => {
        if (data.datas) {
            return data.datas.filter(({ type }) => type === 'pembelian')
                .reduce((a, b) => {
                    return a + b.dataBarang['jumlahBarang'];
                }, 0);
        } else {
            return 0
        }
    }
    const getJumlahPenjualan = () => {
        if (data.datas) {

            return data.datas.filter(({ type }) => type === 'penjualan')
                .reduce((a, b) => {
                    return a + b.dataBarang['jumlahBarang'];
                }, 0);
        } else {
            return 0
        }
    }
    const navigate = useNavigate();
    const detailHandler = (key) => {
        if (key === 0) {

        } else {
            const datadetail = data.datas.find(x => x._id === key)
            // window.open("/oli/" + datadetail.type + "/detail/" + key, "_blank")
            if(datadetail.type === 'penjualan')
            {
                setpenjualanSelected(key)
                setpenjualanDetail(true)

            }else
            {
                setpembelianSelected(key)
                setpembelianDetail(true)
            }
        }
    }
    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="3" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{getjumlahPembelian() + " " + satuanBarang}</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{getJumlahPenjualan() + " " + satuanBarang}</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell"></td>
                <td colSpan="3" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right"></td>

            </tr>
        )
    }




    //untuk masalah page


    const [searchText, setsearchText] = useState("")
    const jumlahShow = 10;
    const [page, setpage] = useState(1)
    const filterData = () => {
        // return data.filter(v => v.namaPelanggan.toLowerCase().includes(searchText.toLowerCase()))
        return data.filter(item => {
            return Object.keys(item).some(key => item[key].toString().toLowerCase().search(searchText.toLowerCase()) !== -1);
        })
        // return Object.keys(item).some(key => item[key].toString().search(searchValue) !== -1);
    }

    const [namaFileExport, setnamaFileExport] = useState("")
    const headerExel = [

        {
            label: "No",
            value: "nomor",
        },
        {
            label: "Tanggal",
            value: "tglTransaksi",
        },
        {
            label: "Nomor Faktur",
            value: "nomorFaktur",
        },
        {
            label: "Pembelian",
            value: "pembelian",
        },
        {
            label: "Penjualan",
            value: "penjualan",
        },
        {
            label: "Stok",
            value: "stok",
        }
    ]

    const isiExcel = () => {
        const result = [];
        let jumlah = 0;



        if (data.datas) {

            const isivalue = {}
            isivalue['nomor'] = "-"
            isivalue['tglTransaksi'] = "Stok Bulan Lalu"
            isivalue['nomorFaktur'] = "-"
            isivalue['pembelian'] = data.bulanSebelumnya.masuk + " " + data.detail.satuanBarang
            isivalue['penjualan'] = data.bulanSebelumnya.keluar + " " + data.detail.satuanBarang
            isivalue['stok'] = data.bulanSebelumnya.sisa + " " + data.detail.satuanBarang
            result.push(isivalue)
            jumlah += data.bulanSebelumnya.sisa;

            data.datas.map((item, index) => {

                jumlah = item.type === "penjualan" ? jumlah - item.dataBarang.jumlahBarang : jumlah + item.dataBarang.jumlahBarang;

                const isi = {}
                isi['nomor'] = index + 1
                isi['tglTransaksi'] = ArhielTgl(item.tglTransaksi)
                isi['nomorFaktur'] = item.nomorFaktur ? NomorFaktur(item.nomorFaktur, item.tglTransaksi) : "-"
                isi['pembelian'] = item.type === "pembelian" ? item.dataBarang.jumlahBarang + " " + data.detail.satuanBarang : "-"
                isi['penjualan'] = item.type === "penjualan" ? item.dataBarang.jumlahBarang + " " + data.detail.satuanBarang : "-"
                isi['stok'] = jumlah + " " + data.detail.satuanBarang
                result.push(isi)
            })
        }
        return result;
    }
    const [printjudulTable, setprintjudulTable] = useState("")
    const [pdfViewr, setpdfViewr] = useState(false)
    const pdfHandler = () => {
        setpdfViewr(true)

    }
    const kembali = () => {
        navigate(`../../${params.kota}`)
    }


    //untuk detail
    const [penjualanDetail, setpenjualanDetail] = useState(false)
    const [penjualanSelected, setpenjualanSelected] = useState("")
    const [pembelianDetail, setpembelianDetail] = useState(false)
    const [pembelianSelected, setpembelianSelected] = useState("")
    return (
        <>
            <ArhielPDFView visible={[pdfViewr, setpdfViewr]}>
                <PDFTable
                    namaData={"Data Stok Oli " + namaKota}
                    judul={printjudulTable}
                    label={headerExel}
                    data={isiExcel()} />
            </ArhielPDFView>
            <ModalWindow visible={[penjualanDetail, setpenjualanDetail]} judul="Detail Penjualan">
                <DetailPenjualan objectId={penjualanSelected} />

            </ModalWindow>
            <ModalWindow visible={[pembelianDetail, setpembelianDetail]} judul="Detail Pembelian">
                <DetailPembelian objectId={pembelianSelected} />

            </ModalWindow>
            <Context>
                <span onClick={kembali} className='flex items-center text-red-500 font-bold mt-1 ml-2 cursor-pointer mb-2'>
                    <FaIcons.FaHandPointLeft color='red' className='mr-1' />Kembali
                </span>
                <div className="bg-white overflow-visible min-h-full rounded-lg shadow-md mr-2 mb-4">

                    <ArhielNavigasi
                        bulan={valueData.bulan}
                        tahun={valueData.tahun}
                        disableType={true}
                        onClick={handleNavClick}
                    />

                    <div className="p-0 pb-2 min-h-full">
                        <div>
                            <JudulTable>{judulTable}</JudulTable>
                        </div>
                        <div className="md:flex justify-between bg-red-200 p-2 align-middle items-center">
                            <div className="w-full pb-2 md:pb-0 items-center justify-between flex md:items-start md:justify-start">
                                <DownloadExcel
                                    label={headerExel}
                                    fileName={namaFileExport}
                                    data={isiExcel()} />
                                <button onClick={pdfHandler} className=" bg-red-500 mr-1 text-xs flex flex-1 md:flex-none  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaFilePdf color="#FFFFFF" />
                                    <span className="ml-2">Print PDF</span>
                                </button>
                            </div>


                            <div className="flex flex-1 justify-between items-center">
                                <button onClick={() => getData(typeData, valueData)} className=" bg-red-500 mr-1 text-xs flex  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaSync className="mr-1" />
                                    <span>Refresh</span>
                                </button>
                                <div className="flex w-min py-1 px-2 bg-white shadow border rounded items-center">
                                    <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                                    <FaIcons.FaSearch color="#FF6600" />
                                </div>
                            </div>
                        </div>
                        <ArhielTable
                            data={dataTable()}
                            state={[data, dispatch]}
                            navrow={true}
                            detailButton={true}
                            detailHandler={detailHandler}
                            initLoading={loadingVisible}
                            footer={footerTable()}
                        />

                    </div>
                </div>
            </Context>
        </>
    )
}

export default TransaksiStok
