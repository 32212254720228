import React from 'react'
import ReactExport from "react-export-excel";
import * as FaIcons from "react-icons/fa";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = props => {

    const button = () => (
        <button  className=" bg-red-500 mr-1  flex flex-1 md:flex-none  text-xs  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
            <FaIcons.FaFileExcel color="#FFFFFF" />
            <span className="ml-2">Export Excel</span>
        </button>
    )

    const header = props.label;
    const dataSet1 = props.data;
    return (

        <ExcelFile filename={props.fileName} element={button()}>
            <ExcelSheet data={dataSet1} name="Employees">
                {
                    header.map((item, index) => {
                        return <ExcelColumn label={item.label} value={item.value} />
                    })
                }
            </ExcelSheet>            
        </ExcelFile>

    )
}

export default DownloadExcel
