import React, { UseEffect, useState } from 'react'
import ModalCover from '../arhiel/components/ModalCover'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

import * as FaIcons from "react-icons/fa";
import { CSVLink, CSVDownload } from "react-csv";

const PrepairDataCSV = props => {

    const [visible, setVisible] = props.visible
    const [isLoading, setisLoading] = props.isLoading
    const data = props.data
    return (
        <ModalCover width="1/3" visible={visible}>
            {isLoading ?
                <div className="align-middle justify-center text-center">
                    <div className="p-1">
                        <Dots color="#FF0000" />
                    </div>
                    <span className="text-red-900 font-bold p-4">Menyiapkan File Data...</span>
                </div>
                :
                <div className="align-middle justify-center text-center">
                    <span className="text-sm font-bold text-gray-700 ">Data File CSV Telah Siap Di Download</span>
                    <div className="p-2 flex items-center justify-center">
                        <CSVLink

                            filename={"Faktur-Penjualasnku.csv"} className="bg-red-500 mr-1 justify-center items-center text-xs flex w-32 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded"
                            data={data}
                            enclosingCharacter=""
                        >
                            <FaIcons.FaFileCsv className="mr-1" />
                            Export CSV
                        </CSVLink>
                        <span onClick={() => setVisible(false)} className="text-xs font-bold ml-3 cursor-pointer text-red-800 active:text-red-500">Tutup</span>
                    </div>
                </div>
                // <div className="border-t-2 border-solid border-gray-200 mt-2 p-2 justify-center items-center">

                //     <span>Data File CSV Telah Siap Di Download</span>
                //     <CSVLink
                //         // asyncOnClick={true}
                //         // onClick={exportData} 
                //         filename={"Faktur-Penjualasnku.csv"} className=" flex-1 bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded"
                //         data={data}
                //         // separator=";"
                //         enclosingCharacter=""

                //     // headers={headers}
                //     >
                //         <FaIcons.FaFileCsv className="mr-1" />
                //         Export CSV
                //     </CSVLink>
                // </div>

            }
        </ModalCover>
    )
}

export default PrepairDataCSV
