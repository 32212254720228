import React, { useState, useEffect } from 'react'
import ArhielDatePicker from '../../arhiel/components/form/ArhielDatePicker'
import ArhielLabel from '../../arhiel/components/form/ArhielLabel'
import JudulPage from '../../arhiel/components/JudulPage'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable'
import * as FaIcons from "react-icons/fa";
import PrepairDataCSV from '../../components/PrepairDataCSV'
import Context from '../Context';

const ExportCSV = () => {
    const [tglMulai, settglMulai] = useState("")
    const [tglSelesai, settglSelesai] = useState("")
    const [eclosingchar, seteclosingchar] = useState("-")
    const [dataexport, setdataexport] = useState([])
    useEffect(() => {
        // alert('a')
    }, [eclosingchar])
    const exportData = (event, done) => {
        if (tglMulai === "" || tglSelesai === "") {
            alert("Pilih Tanggal Mulai Dan Tgl Selesai Untuk Membuat File CSV")

        } else {

            setmodalPrepaire(true)
            setmodalPrepaireIsLoading(true)
            const data = [
                [

                    'FK', 'KD_JENIS_TRANSAKSI', 'FG_PENGGANTI', 'NOMOR_FAKTUR', 'MASA_PAJAK', 'TAHUN_PAJAK', 'TANGGAL_FAKTUR', 'NPWP', 'NAMA', 'ALAMAT_LENGKAP', 'JUMLAH_DPP', 'JUMLAH_PPN', 'JUMLAH_PPNBM', 'ID_KETERANGAN_TAMBAHAN', 'FG_UANG_MUKA', 'UANG_MUKA_DPP', 'UANG_MUKA_PPN', 'UANG_MUKA_PPNBM', 'REFERENSI'
                ],
                [
                    'LT', 'KODE_OBJEK', 'NAMA', 'JALAN', 'BLOK', 'NOMOR', 'RT', 'RW', 'KECAMATAN', 'KELURAHAN', 'KABUPATEN', 'PROPINSI', 'KODE_POS', 'NOMOR_TELEPON', '', '', '', '', ''
                ],
                [
                    'OF', 'KODE_OBJEK', 'NAMA', 'HARGA_SATUAN', 'JUMLAH_BARANG', 'HARGA_TOTAL', 'DISKON', 'DPP', 'PPN', 'TARIF_PPNBM', 'PPNBM', '', '', '', '', '', '', '', ''
                ]
            ];
            const linkmulai = tglMulai.getFullYear() + "-" + (tglMulai.getMonth() + 1) + "-" + tglMulai.getDate()
            const linkselesai = tglSelesai.getFullYear() + "-" + (tglSelesai.getMonth() + 1) + "-" + tglSelesai.getDate()
            axios.get(SERVER + "/penjualan/export/" + linkmulai + "/" + linkselesai, {withCredentials:true}).then(res => {
                res.data.map((item, index) => {

                    const listfk = [];
                    let totalBayar = 0;
                    item.barang.map((barang, indexbarang) => {
                        totalBayar += barang.jumlahAkhir
                        const of = []
                        of.push("OF");
                        of.push(barang.kodeBarang);
                        of.push("\"" + barang.namaBarang + "\"");
                        of.push(Math.round(barang.hargaSatuan / 1.1));
                        of.push(barang.jumlahBarang);
                        of.push(Math.round(barang.jumlahAkhir / 1.1));
                        of.push(0);
                        of.push(Math.round(barang.jumlahAkhir / 1.1));
                        of.push(Math.round(barang.jumlahAkhir / 1.1 * (10 / 100)));
                        of.push(0);
                        of.push(0);
                        of.push("");
                        of.push("");
                        of.push("");
                        of.push("");
                        of.push("");
                        of.push("");
                        of.push("");
                        of.push("");
                        listfk.push(of)
                    })

                    const fk = [];

                    const tgl = new Date(item.tglTransaksi)
                    const formattgl = tgl.getDate() + "/" + (tgl.getMonth() + 1) + "/" + tgl.getFullYear()


                    fk.push("FK");
                    fk.push("01");
                    fk.push("0");
                    fk.push(item.fakturPenjualan);
                    fk.push(tgl.getMonth() + 1);
                    fk.push(tgl.getFullYear());
                    fk.push(formattgl);
                    fk.push(item.dataPelanggan.npwpPelanggan);

                    if (item.dataPelanggan.nikPelanggan === '0000000000000000') {
                        fk.push(item.dataPelanggan.nikPelanggan)
                    } else {
                        fk.push("\"" + item.dataPelanggan.nikPelanggan + "#NIK#NAMA#" + item.dataPelanggan.namaPelanggan + "\"")

                    }


                    if (item.dataPelanggan.alamatPelanggan != "") {
                        fk.push("\"" + item.dataPelanggan.alamatPelanggan + "\"")
                    } else {
                        fk.push("\"---\"")
                    }

                    fk.push(Math.round(totalBayar / 1.1));
                    fk.push(Math.round(totalBayar / 1.1 * (10 / 100)));
                    fk.push(0);
                    fk.push(0);
                    fk.push(0);
                    fk.push(0);
                    fk.push(0);
                    fk.push(0);
                    fk.push("");

                    data.push(fk)
                    listfk.forEach(element => {
                        data.push(element)
                    });
                   
                })
                console.log(data)
                setdataexport(data)
                setmodalPrepaireIsLoading(false)
            });
        }
    }




    const [modalPrepaire, setmodalPrepaire] = useState(false)
    const [modalPrepaireIsLoading, setmodalPrepaireIsLoading] = useState(false)
    return (
        <Context>
            <PrepairDataCSV data={dataexport} isLoading={[modalPrepaireIsLoading, setmodalPrepaireIsLoading]} visible={[modalPrepaire, setmodalPrepaire]} />
            <JudulPage>Export Faktur Penjualan</JudulPage>
            <div className="md:flex">
                <div className="md:flex-1 p-2">
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Tanggal Mulai
                        </ArhielLabel>
                        <ArhielDatePicker value={[tglMulai, settglMulai]} />
                    </div>

                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Tanggal Selesai
                        </ArhielLabel>
                        <ArhielDatePicker minDate={tglMulai} value={[tglSelesai, settglSelesai]} />
                    </div>
                </div>
            </div>
            <div className="border-t-2 border-solid border-gray-200 mt-2 p-2 flex justify-end items-center">
                <span className="mr-2 text-sm font-bold text-gray-500 italic">Download File CSV</span>


                <button onClick={exportData} className=" bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                    <FaIcons.FaSave className="mr-1" />
                    <span>Export</span>
                </button>
            </div>
        </Context>
    )
}

export default ExportCSV
