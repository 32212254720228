import React, { useState } from 'react'
import * as FaIcons from "react-icons/fa";
import DatePicker from 'react-date-picker'
import { NamaBulan } from '../../function/ArhielMonth';

const ArhielNavigasi = props => {


    const [tglData, settglData] = useState(new Date())
    const [bulanData, setbulanData] = useState(props.bulan ? props.bulan : new Date().getMonth() + 1)
    const [tahunData, settahunData] = useState(props.tahun ? props.tahun : new Date().getFullYear())

    const createMonth = () => {
        const option = []
        for (let i = 1; i <= 12; i++) {
            option.push(<option value={i}>{NamaBulan[i - 1]}</option>)


        }
        return option
    }
    const createYear = () => {
        const thisYear = new Date().getFullYear()

        const option = []
        for (let i = 2020; i <= thisYear; i++) {
            option.push(<option value={i}>{i}</option>)
        }
        return option
    }

    const [typeTampilan, settypeTampilan] = useState(props.disableType ? "bulanan" : "harian")
    const changeTypeHandler = v => {
        settypeTampilan(v.target.value)
    }

    const klikHandler = () => {
        if (typeTampilan === "harian") {

            props.onClick(typeTampilan, tglData)

        } else {
            props.onClick(typeTampilan, { bulan: bulanData, tahun: tahunData })
        }
    }

    return (
        <div>
            <div className="block p-2 border-2 bg-red-500 border-solid border-gray-100 rounded-lg mb-2 md:mb-0 md:flex">
                {
                    props.disableType ?
                        null
                        : <div>
                        <label className="font-bold text-white mr-2 block mb-1 text-sm w-full md:w-max md:inline-flex">
                            Tampilkan Data :
                        </label>
                        <select value={typeTampilan} onChange={changeTypeHandler} className="bg-white p-1 w-full md:w-min mb-2 md:mb-0 rounded-md text-sm text-gray-700">
                            <option value="harian">Harian</option>
                            <option value="bulanan">Bulanan</option>
                        </select>
                    </div>
                }
                {
                    typeTampilan === 'bulanan' ?
                        <div className="md:flex md:ml-2">
                            <label className="items-center p-0 font-bold mb-1 md:mb-0  text-white mr-2 text-sm block md:inline-flex">
                                Pilih Bulan :
                            </label>
                            <div className="flex">
                                <select value={bulanData} onChange={v => setbulanData(v.target.value)} className="mr-2 flex-1 md:flex-none bg-white p-1 rounded-md text-sm text-gray-700">
                                    {createMonth()}
                                </select>

                                <select value={tahunData} onChange={v => settahunData(v.target.value)} className="bg-white  md:flex-none flex-1 p-1 rounded-md text-sm text-gray-700">
                                    {createYear()}
                                </select>
                            </div>
                        </div>
                        :
                        <div className="md:flex md:ml-2">
                            <label className="items-center p-0 font-bold mb-1 md:mb-0 flex-1  text-white mr-2 text-sm block md:inline-flex">
                                Pilih Tanggal :
                            </label>
                            <DatePicker
                                onChange={value => settglData(value)}
                                className="text-sm w-full md:w-min bg-white rounded-md px-2 py-0.5"
                                value={tglData}
                                clearIcon={null}
                                name="Arhiel"
                                dayPlaceholder="01"
                                monthPlaceholder="01"
                                showMonthYearPicker
                                yearPlaceholder="2021"
                                calendarIcon={<FaIcons.FaCalendar color="#FF0000" />}
                                format="dd-M-yyyy"
                            />
                        </div>
                }
                <button onClick={klikHandler} className=" bg-red-600 mt-2 md:mt-0 md:ml-2 text-xs flex items-center hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">
                    <FaIcons.FaDemocrat className="mr-1" />
                    <span className="text-xs">Tampilkan</span>
                </button>
            </div>

        </div>
    )
}

export default ArhielNavigasi
