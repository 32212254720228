import React, {useState} from 'react'
import ModalSelect from './ModalSelect'

const ArhielSelectWithModal = props => {

    const data = props.data
    const [modal, setModal] = useState(false)
    
    const onModalPilih = (item) =>{
        setModal(false)
        props.onSelect(item)
    }
    return (
        <>

            <ModalSelect onSelect={onModalPilih} data={data} visible={[modal, setModal]} />
            <div className="shadow items-center justify-center block border rounded w-full py-1.5 px-1 text-gray-700">
                <button onClick={()=>setModal(true)} className="bg-red-400 mr-2 rounded-sm shadow-sm hover:bg-red-700 text-white py-1 px-2 text-xs font-bold">Pilih</button>
                <span className="flex-1 font-bold text-xs text-gray-700">{props.value}</span>

            </div>
        </>
    )
}

export default ArhielSelectWithModal
