import React from 'react'
import ModalCover from './ModalCover'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import * as FaIcons from "react-icons/fa";


const ArhielPDFView = props => {
    const [visible, setVisible] = props.visible

    return (
        <ModalCover visible={visible} width="full" bg="red-700">

            <div className="items-center flex justify-end p-2">
                <FaIcons.FaTimes className="cursor-pointer" color="#FFFFFF" onClick={() => setVisible(false)} />
                {/* <button className="bg-gray-100 p-2 text-sm rounded-md" onClick={}>Tutup</button> */}
            </div>
            
            <PDFViewer className="w-full h-96">

                {props.children}


            </PDFViewer>
        </ModalCover>
    )
}

export default ArhielPDFView
