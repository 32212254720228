import React, { useReducer, useEffect, useState } from 'react'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable';
import Context from '../../Context';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable';
import JudulPage from '../../../arhiel/components/JudulPage';
import ArhielNavigasi from '../../../arhiel/components/ArhielNavigasi';
import ArhielTableNav from '../../../arhiel/components/TablePage/ArhielTableNav';
import JudulTable from '../../../arhiel/components/JudulTable';

import * as FaIcons from "react-icons/fa";
import { NamaBulan } from '../../../function/ArhielMonth';

import DownloadExcel from '../../../arhiel/components/DownloadExcel';
import PDFTable from '../../../components/PDFTable';
import ArhielPDFView from '../../../arhiel/components/ArhielPDFView';

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.data
        case 'delete':
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        default:
            return state;
    }
}

const DataStok = props => {

    const params = useParams()
    const kota = params.kota || "toli-toli"
    const [namaKota, setnamaKota] = useState(kota)
    const [data, dispatch] = useReducer(reducer, [])
    const [loadingVisible, setloadingVisible] = useState(true)


    const [typeData, settypeData] = useState("bulanan")
    const [valueData, setvalueData] = useState({ bulan: new Date().getMonth() + 1, tahun: new Date().getFullYear() })

    useEffect(() => {

        setloadingVisible(false)
        getData(typeData, valueData)
    }, [])
    useEffect(() => {
        // alert('a')
        // ... ini kita bikin karna terjadi masalah saat pindah menu view tidak terender
        setnamaKota(kota)

        getData(typeData, valueData)
    }, [params.kota]);

    const [judulTable, setjudulTable] = useState("")
    const getData = (type, value) => {
        setloadingVisible(true)
        setsearchText("")
        setpage(1)

        const link = `${value.bulan}/${value.tahun}`

        // alert(SERVER + "oli/penjualan/" +link)
        axios.get(SERVER + "/stok/" + kota + "/" + link, {withCredentials:true}).then((res) => {

            setjudulTable(<><p className="capitalize">Data Stok Oli {kota}</p><p>Bulan {NamaBulan[value.bulan - 1]} {value.tahun}</p></>)

            setloadingVisible(false)
            setnamaFileExport("Data Stok Oli Bulan " + NamaBulan[value.bulan - 1] +" "+  value.tahun)
            setprintjudulTable("Bulan " + NamaBulan[value.bulan - 1] +" "+  value.tahun)
            dispatch({ type: 'init', data: res.data })
            // console.log(res.data)
        })
    }

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", 'KodeBarang', "Nama Barang", "Stok Bulan Lalu", "Pembelian", "Penjualan", "Stok Akhir"
        ]
        const value = [];
        filterData().slice((page - 1) * jumlahShow, (page - 1) + ((jumlahShow - 1) * page) + 1).map((item, index) => {
            const isivalue = []
            isivalue.push({ value: index + 1, align: 'text-center' })
            isivalue.push({ value: item.kodeBarang, align: 'text-center' })
            isivalue.push({ value: item.namaBarang, align: 'text-left' })
            isivalue.push({ value: item.stokBulanLalu + " " + item.satuanBarang, align: 'text-right' })
            isivalue.push({ value: item.barangMasuk + " " + item.satuanBarang, align: 'text-right' })
            isivalue.push({ value: item.barangKeluar + " " + item.satuanBarang, align: 'text-right' })
            isivalue.push({ value: item.stokAkhir + " " + item.satuanBarang, align: 'text-right' })
            value.push({ key: item._id, value: isivalue })
            return false
        })
        json['value'] = value;
        json['property'] = {

        }
        return json;
    }
    const navigate = useNavigate();
    const detailHandler = (key) => {



        const link = `${key}/${valueData.bulan}/${valueData.tahun}`
        navigate(link)
        // alert(link)
        // alert(link)
        // window.open("/oli/stok/" + link, "_blank")
        // history.push("/oli/stok/" + link)
    }




    //untuk masalah page


    const [searchText, setsearchText] = useState("")
    const jumlahShow = 10;
    const [page, setpage] = useState(1)
    const filterData = () => {
        // return data.filter(v => v.namaPelanggan.toLowerCase().includes(searchText.toLowerCase()))
        return data.filter(item => {
            return Object.keys(item).some(key => item[key].toString().toLowerCase().search(searchText.toLowerCase()) !== -1);
        })
        // return Object.keys(item).some(key => item[key].toString().search(searchValue) !== -1);
    }

    const getJumlahPage = () => {
        if (filterData().length === 0) {
            return 1;
        } else {

            const sisa = filterData().length % jumlahShow
            if (sisa === 0) {
                return Math.floor(filterData().length / jumlahShow)
            } else {
                return (Math.ceil(filterData().length / jumlahShow))
            }
        }
    }

    //untuk masalah print dan export
    const [namaFileExport, setnamaFileExport] = useState("")
    const headerExel = [

        {
            label: "No",
            value: "nomor",
        },
        {
            label: "Kode Barang",
            value: "kodeBarang",
        },
        {
            label: "Nama Barang",
            value: "namaBarang",
        },
        {
            label: "Stok Bulan Lalu",
            value: "stokBulanLalu",
        },
        {
            label: "Pembelian",
            value: "barangMasuk",
        },
        {
            label: "Penjualan",
            value: "barangKeluar",
        },
        {
            label: "Stok Akhir",
            value: "stokAkhir",
        }
    ]

    const isiExcel = () => {
        const result = [];
        
        data.map((item, index) => {
            
            const isi = {}
            isi['nomor'] = index + 1
            isi['kodeBarang'] = item.kodeBarang
            isi['namaBarang'] = item.namaBarang
            isi['stokBulanLalu'] = item.stokBulanLalu + " " + item.satuanBarang
            isi['barangMasuk'] = item.barangMasuk + " " + item.satuanBarang
            isi['barangKeluar'] = item.barangKeluar + " " + item.satuanBarang
            isi['stokAkhir'] = item.stokAkhir + " " + item.satuanBarang
            result.push(isi)
        })
        return result;
    }
    const [printjudulTable, setprintjudulTable] = useState("")
    const [pdfViewr, setpdfViewr] = useState(false)
    const pdfHandler = () => {
        setpdfViewr(true)

    }

    return (
        <>
            <ArhielPDFView visible={[pdfViewr, setpdfViewr]}>
                <PDFTable
                    namaData={"Data Stok Oli " + namaKota}
                    judul={printjudulTable}
                    label={headerExel}
                    data={isiExcel()} />
            </ArhielPDFView>
            <Context>

                <div className="bg-white overflow-visible min-h-full rounded-lg shadow-md mr-2 mb-4">
                    
                    <ArhielNavigasi disableType={true}
                        onClick={(type, value) => { settypeData(type); setvalueData(value); getData(type, value) }}
                    />

                    <div className="p-0 pb-2 min-h-full">
                        <div>
                            <JudulTable>{judulTable}</JudulTable>
                        </div>
                        <div className="md:flex justify-between bg-red-200 p-2 align-middle items-center">
                            <div className="w-full pb-2 md:pb-0 items-center justify-between flex md:items-start md:justify-start">
                                <DownloadExcel
                                    label={headerExel}
                                    fileName={namaFileExport}
                                    data={isiExcel()} />
                                <button onClick={pdfHandler} className=" bg-red-500 mr-1 text-xs flex flex-1 md:flex-none  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaFilePdf color="#FFFFFF" />
                                    <span className="ml-2">Print PDF</span>
                                </button>
                            </div>


                            <div className="flex flex-1 justify-between items-center">
                                <button onClick={() => getData(typeData, valueData)} className=" bg-red-500 mr-1 text-xs flex  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaSync className="mr-1" />
                                    <span>Refresh</span>
                                </button>
                                <div className="flex w-min py-1 px-2 bg-white shadow border rounded items-center">
                                    <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                                    <FaIcons.FaSearch color="#FF6600" />
                                </div>
                            </div>
                        </div>
                        <ArhielTable
                            data={dataTable()}
                            state={[data, dispatch]}
                            navrow={true}
                            detailButton={true}
                            detailHandler={detailHandler}
                            // editHandler={editHandler}
                            initLoading={loadingVisible}
                        // footer={footerTable()} 
                        />
                        <ArhielTableNav page={[page, setpage]} jumlahPage={getJumlahPage()} />
                    </div>
                </div>
            </Context>
        </>
    )
}

export default DataStok
