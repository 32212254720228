import React, { useState, useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

const Stok = props => {

    const params = useParams()
    const navigate = useNavigate()
    const [kota, setkota] = useState(params.kota || "toli-toli")

    const gantikota = namakota => {
        setkota(namakota)
        if(params.idBarang)
        {
            
            navigate(`${namakota}/${params.idBarang}/${params.bulan}/${params.tahun}`)
        }else
        {
            navigate(namakota)
        }
    }

    return (
        <div className="min-h-full pt-2 bg-red-200">

            <ul className="pl-2">
                <li onClick={() => gantikota("toli-toli")} className={"text-white px-5 py-2 text-sm rounded-tl-md rounded-tr-3xl cursor-pointer hover:bg-red-800 font-bold ml-0 inline-block " + (kota === "toli-toli" ? "bg-red-600" : "bg-red-400")}
                > Data Stok Toli-Toli
                </li>
                <li onClick={() => gantikota("buol")} className={"text-white px-5 py-2 text-sm rounded-tl-md rounded-tr-3xl cursor-pointer hover:bg-red-800 font-bold ml-0 inline-block " + (kota === "buol" ? "bg-red-600" : "bg-red-400")}
                > Data Stok Buol
                </li>
            </ul>
            <div className="bg-white  p-2 rounded-lg shadow-md">
                <Outlet />
            </div>
        </div>
        // <Outlet />
    )
}

export default Stok
