const NamaLevelUser = level => {
    switch (parseInt(level)) {
        case 0:
            return "Administrator"
        case 1:
            return "User Oli"
        case 2:
            return "User Pajak"
        default:
            return "-"
    }
}
export default NamaLevelUser