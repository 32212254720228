import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
const ModalWindow = props => {
    const [visible, setVisible] = props.visible
    return (
        visible ?
            <div
                className="justify-center bg-black bg-opacity-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none "
            >
                <div className={"relative py-7 w-full md:w-3/4 h-full"}>
                    <div className="flex-col h-full flex rounded-lg overflow-hidden">
                        <div className='bg-red-700 flex justify-between items-center'>
                            <h3 className="px-2 py-2 text-white font-bold border-red-700 text-sm">{props.judul}</h3>
                            <span onClick={() => {setVisible(false)}} className='mr-3 font-bold text-red-200 hover:text-white cursor-pointer'>X</span>
                        </div>
                        <div className='flex-1'>
                            <Scrollbars>
                                {props.children}
                            </Scrollbars>
                        </div>
                    </div>

                    {/* <div className='bg-red-500 h-full p-2 rounded-lg shadow-lg'>
                        
                        
                        <Scrollbars className='bg-white'>
                            <div className={"border-0 relative flex flex-col w-full bg-white outline-none focus:outline-none"}>
                                <div className="items-start justify-between border-b border-solid border-blueGray-200 rounded-t">


                                    {props.children}
                                </div>
                            </div>
                        </Scrollbars>
                    </div> */}
                </div>
            </div>
            :
            null
    )
}

export default ModalWindow
