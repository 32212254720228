import React, { useState } from 'react'

import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
const ContextCenter = props => {

    // const isLoading = true
    const isLoading = props.loading
    return (
        <div className="flex justify-center p-2 absolute left-0 right-0 min-h-full bg-gray-300">
            <div className="bg-white md:w-2/3 p-2  rounded-lg shadow-md">
                {
                    isLoading ?
                        <div className="align-middle justify-center text-center">
                            <div className="p-1">
                                <Dots color="#FF0000" />
                            </div>
                            <span className="text-red-900 font-bold p-4">Mohon Tunggu...</span>
                        </div>
                        :

                        props.children

                }
            </div>
        </div>
    )
}

export default ContextCenter
