import React from 'react'

const JudulTable = props => {
    return (
        <div className="p-2 text-center">
            <span className="text-center font-bold text-red-900 text-lg">{props.children}</span>
        </div>
    )
}

export default JudulTable
