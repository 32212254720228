import React, { useState, useReducer, useEffect } from 'react'
import ArhielLabel from '../../../arhiel/components/form/ArhielLabel'
import JudulPage from '../../../arhiel/components/JudulPage'
import Context from '../../Context'
import { Rp } from '../../../function/Rupiah'

import * as FaIcons from "react-icons/fa";
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable'
import NomorFaktur from '../../../function/NomorFaktur'
import ArhielValue from '../../../arhiel/components/form/ArhielValue'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable'
import { ArhielTgl } from '../../../function/ArhielTgl'
import Loading from '../../../arhiel/components/Loading'
import Confirm from '../../../arhiel/components/Confirm'
import ArhielPDFView from '../../../arhiel/components/ArhielPDFView'
import PDFKwitansi from '../../../components/PDFKwitansi'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

const DetailPenjualan = props => {

    const [isLoading, setisLoading] = useState(true)
    const [data, setdata] = useState(null)



    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", "Kode Barang", "Nama Barang", "Harga Satuan", "Jumlah Barang", "Jumlah Pembayaran", "Discount", "Total Bayar"
        ]
        const value = [];
        if (data.barang) {
            data.barang.map((item, index) => {
                const isivalue = []
                isivalue.push({ value: index + 1, align: 'text-center' })
                isivalue.push({ value: item.kodeBarang, align: 'text-center' })
                isivalue.push({ value: item.namaBarang, align: 'text-left' })
                isivalue.push({ value: Rp(item.hargaSatuan, false), align: 'text-right' })
                isivalue.push({ value: item.jumlahBarang + " " + item.satuanBarang, align: 'text-right' })
                isivalue.push({ value: Rp(item.hargaSatuan * item.jumlahBarang, false), align: 'text-right' })
                isivalue.push({ value: Rp(item.diskon / 100 * (item.jumlahBarang * item.hargaSatuan), false), align: 'text-right' })
                isivalue.push({ value: Rp((item.hargaSatuan * item.jumlahBarang) - (item.diskon / 100 * (item.hargaSatuan * item.jumlahBarang)), false), align: 'text-right' })
                value.push({ key: item._id, value: isivalue })
                return false
            })
        }

        json['value'] = value;
        json['property'] = {

        }
        return json;
    }


    const [addForm, setaddForm] = useState(false)

    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="7" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total Jumlah Bayar</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getjumlah(), true)}</td>

            </tr>
        )
    }
    const getjumlah = () => {
        if (data.barang) {

            return data.barang.reduce((a, b) => {
                return a + ((b['hargaSatuan'] * b['jumlahBarang']) - ((b['diskon'] / 100) * (b['hargaSatuan'] * b['jumlahBarang'])));
            }, 0);
        } else {
            return 0
        }
    }











    useEffect(() => {
        axios.get(SERVER + "/penjualan/" + props.objectId, { withCredentials: true }).then(res => {
            setisLoading(false)
            console.log(res.data[0])
            if (res.status === 200) {
                setdata(res.data[0])
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])


    const [confirmDelete, setconfirmDelete] = useState(false)
    const [modalLoading, setmodalLoading] = useState(false)
    const yesDeleteHandler = () => {
        setconfirmDelete(false)
        setmodalLoading(true)

        setmodalLoading(false)
        axios.delete(SERVER + "/penjualan/" + props.objectId).then(res => {

            setmodalLoading(false)
            const statusCode = res.status
            if (statusCode == 200) {
                // history.push("/oli/penjualan/data/")


            } else {
                alert("Terjadi Massalah Error Code " + statusCode)
            }
        }).catch(err => {
            alert("Terjadi Massalah Error Code " + err)
        })
    }
    const deleteHandler = () => {

        setconfirmDelete(true)

    }
    const [pdfViewr, setpdfViewr] = useState(false)
    const pdfHandler = () => {
        setpdfViewr(true)

    }
    return (
        data ?
            <>
                <ArhielPDFView visible={[pdfViewr, setpdfViewr]}>
                    <PDFKwitansi data={data} />
                </ArhielPDFView>
                <Loading visible={[modalLoading, setmodalLoading]} />
                <Confirm
                    visible={[confirmDelete, setconfirmDelete]}
                    title="Hapus Data penjualan?"
                    titleyes="Hapus"
                    yes={yesDeleteHandler} />
                <Context loading={isLoading}>

                    <div className=" items-center justify-end p-2 mt-2  md:p-0 md:mt-0 flex">

                        <PDFDownloadLink document={<PDFKwitansi data={data} />} fileName={NomorFaktur(data.nomorFaktur, data.tglTransaksi)}>
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                <span className="flex items-center mr-3 cursor-pointer text-sm hover:bg-gray-100 py-1 rounded-md px-3">
                                    <FaIcons.FaDownload color="#FF0000" />
                                    <label className="ml-1 text-red-700 cursor-pointer font-bold">Download PDF</label>
                                </span>
                            )}
                        </PDFDownloadLink>

                        <span onClick={pdfHandler} className="flex items-center mr-3 cursor-pointer text-sm hover:bg-gray-100 py-1 rounded-md px-3">
                            <FaIcons.FaFilePdf color="#FF0000" />
                            <label className="ml-1 text-red-700 cursor-pointer font-bold">Print PDF</label>
                        </span>
                    </div>
                    <div className="md:flex">
                        <div className="md:flex-1 p-2">
                            <div className="mb-4 items-center border-b-2 border-solid border-gray-300 block md:flex">
                                <ArhielLabel>
                                    Tanggal Transaksi
                                </ArhielLabel>
                                <ArhielValue>

                                    {data ? ArhielTgl(data.tglTransaksi) : null}
                                </ArhielValue>
                            </div>

                            <div className="mb-4 items-center border-b-2 border-solid border-gray-300 justify-between block md:flex">
                                <ArhielLabel>
                                    Kota
                                </ArhielLabel>
                                <ArhielValue>{data.kota}</ArhielValue>
                            </div>
                            <div className="mb-4 items-center border-b-2 border-solid border-gray-300 block md:flex">
                                <ArhielLabel>
                                    Nomor Faktur
                                </ArhielLabel>
                                <ArhielValue>{NomorFaktur(data.nomorFaktur, data.tglTransaksi)}</ArhielValue>
                            </div>
                        </div>
                        <div className="md:flex-1 p-2">

                            <div className="mb-4 items-center border-b-2 border-solid border-gray-300 justify-between block md:flex">
                                <ArhielLabel>
                                    Pelanggan
                                </ArhielLabel>
                                {
                                    data.dataPelanggan ?

                                        <ArhielValue>{data.dataPelanggan.kodePelanggan} - {data.dataPelanggan.namaPelanggan}</ArhielValue>
                                        : null
                                }
                            </div>
                            <div className="mb-4 items-center border-b-2 border-solid border-gray-300 block md:flex">
                                <ArhielLabel>
                                    Tanggal Jatuh Tempo
                                </ArhielLabel>
                                <ArhielValue>
                                    {ArhielTgl(data.tglJatuhTempo)}
                                </ArhielValue>
                            </div>
                        </div>

                    </div>
                    <div className="bg-red-200 p-2 rounded-md shadow-md">
                        <ArhielTable
                            data={dataTable()}
                            addForm={[addForm, setaddForm]}
                            state={[data, () => { }]}
                            navrow={false}

                            footer={footerTable()}
                        />
                    </div>

                </Context>
            </> :
            <div className="align-middle bg-white justify-center text-center pb-10">
                <div className="p-1">
                    <Dots color="#FF0000" />
                </div>
                <span className="text-red-900 font-bold p-4">Mohon Tunggu...</span>
            </div>
    )
}

export default DetailPenjualan
