export const ArhielTgl = (tgl) => {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const tgltrx = new Date(tgl)
    const tglRes = tgltrx.toLocaleDateString("id-ID", options)
    return tglRes;
}
export const ArhielTglLessDay = (tgl) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  const tgltrx = new Date(tgl)
  const tglRes = tgltrx.toLocaleDateString("id-ID", options)
  return tglRes;
}
export const ArhielTglPertama = date => {
    
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export const arhielTglAkhir = date => {
    
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }