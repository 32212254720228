import React from 'react'

const ArhielValue = props => {
    return (
        <span className=" text-sm  w-full py-2 px-2 text-gray-700  ">
            {props.children}
        </span>
    )
}

export default ArhielValue
