import React, { useReducer, useEffect, useState } from 'react'
import ArhielTable from '../../arhiel/components/TablePage/ArhielTable';
import Context from '../Context';
import { Rp } from '../../function/Rupiah';
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable';
import JudulPage from '../../arhiel/components/JudulPage';
import { ArhielTgl } from '../../function/ArhielTgl';
import ArhielNavigasi from '../../arhiel/components/ArhielNavigasi';
import ArhielTableNav from '../../arhiel/components/TablePage/ArhielTableNav';
import JudulTable from '../../arhiel/components/JudulTable';

import * as FaIcons from "react-icons/fa";
import { NamaBulan } from '../../function/ArhielMonth';

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.data

        case 'editFaktur':
            state[action.index].fakturPenjualan = action.data;
            return state;
        default:
            return state;
    }
}


const InputFaktur = props => {

    const [data, dispatch] = useReducer(reducer, [])
    const [loadingVisible, setloadingVisible] = useState(true)


    const [typeData, settypeData] = useState("harian")
    const [valueData, setvalueData] = useState(new Date())

    useEffect(() => {

        setloadingVisible(false)
        getData(typeData, valueData)
    }, [])

    const [judulTable, setjudulTable] = useState("")
    const getData = (type, value) => {
        setloadingVisible(true)
        setsearchText("")
        setpage(1)
        let link = "";
        type === "harian" ? link = "data/" + value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate()
            : link = `${value.bulan}/${value.tahun}`
        // alert(SERVER + "oli/penjualan/" +link)
        axios.get(SERVER + "/penjualan/" + link, {withCredentials:true}).then((res) => {
            if (type === "harian") {

                setjudulTable(<><p>Data Penjualan</p><p>{ArhielTgl(value)}</p></>)
            } else {
                setjudulTable(<><p>Data Penjualan</p><p>Bulan {NamaBulan[value.bulan - 1]} {value.tahun}</p></>)
            }
            setloadingVisible(false)
            dispatch({ type: 'init', data: res.data })
            // console.log(res.data)
        })
    }

    const onchangeFaktur = (key, value, setloading, setedited) => {
        // setloading(true)

        // alert(key)
        if (value === null || value === "") {
            alert("Masukan Nomor Faktur")
        } else {
           
            setloading(true)
            axios.put(SERVER + "/penjualan/setFaktur/" + key, { fakturPenjualan: value }, {withCredentials:true}).then(res => {
                // alert(res)
                // const objIndex = data.findIndex(obj => obj._id === key);
                // dispatch({type:'edit', index : objIndex, data : value})
                setloading(false)
                setedited(false)
                // console.log(res)
            })
        }
    }
    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", 'Kota', "Tanggal", "Pelanggan", "Faktur Penjualan", "DPP", "PPN", "Jumlah Bayar"
        ]
        const value = [];
        filterData().slice((page - 1) * jumlahShow, (page - 1) + ((jumlahShow - 1) * page) + 1).map((item, index) => {
            const isivalue = []
            isivalue.push({ value: index + 1, align: 'text-center' })
            isivalue.push({ value: item.kota, align: 'text-center' })
            isivalue.push({ value: ArhielTgl(item.tglTransaksi), align: 'text-center' })
            isivalue.push({ value: item.dataPelanggan.namaPelanggan, align: 'text-left' })
            isivalue.push({ value: item.fakturPenjualan, align: 'text-left', editable: true, onChange: onchangeFaktur })
            isivalue.push({ value: Rp(item.totalBayar / 1.1, true), align: 'text-right' })
            isivalue.push({ value: Rp(item.totalBayar / 1.1 * (10 / 100), true), align: 'text-right' })
            isivalue.push({ value: Rp(item.totalBayar, true), align: 'text-right' })
            value.push({ key: item._id, value: isivalue })
            return false
        })
        json['value'] = value;
        json['property'] = {

        }
        return json;
    }





    //untuk masalah page


    const [searchText, setsearchText] = useState("")
    const jumlahShow = 10;
    const [page, setpage] = useState(1)
    const filterData = () => {
        // return data.filter(v => v.namaPelanggan.toLowerCase().includes(searchText.toLowerCase()))
        return data.filter(item => {
            return Object.keys(item).some(key => item[key].toString().toLowerCase().search(searchText.toLowerCase()) !== -1);
        })
        // return Object.keys(item).some(key => item[key].toString().search(searchValue) !== -1);
    }

    const getJumlahPage = () => {
        if (filterData().length === 0) {
            return 1;
        } else {

            const sisa = filterData().length % jumlahShow
            if (sisa === 0) {
                return Math.floor(filterData().length / jumlahShow)
            } else {
                return (Math.ceil(filterData().length / jumlahShow))
            }
        }
    }

    //untuk masalah header


    return (
        <>


            <Context>

                <div className="bg-white overflow-visible min-h-full rounded-lg shadow-md mr-2 mb-4">
                    <JudulPage>
                        Input Faktur penjualan
                    </JudulPage>
                    <ArhielNavigasi
                        onClick={(type, value) => { settypeData(type); setvalueData(value); getData(type, value) }}
                    />

                    <div className="p-0 pb-2 min-h-full">
                        <div>
                            <JudulTable>{judulTable}</JudulTable>
                        </div>
                        <div className="md:flex justify-between bg-red-200 p-2 align-middle items-center">



                            <div className="flex flex-1 justify-between items-center">
                                <button onClick={() => getData(typeData, valueData)} className=" bg-red-500 mr-1 text-xs flex  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaSync className="mr-1" />
                                    <span>Refresh</span>
                                </button>
                                <div className="flex w-min py-1 px-2 bg-white shadow border rounded items-center">
                                    <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                                    <FaIcons.FaSearch color="#FF6600" />
                                </div>
                            </div>
                        </div>
                        <ArhielTable
                            data={dataTable()}
                            state={[data, dispatch]}
                            navrow={false}
                            initLoading={loadingVisible}
                        />
                        <ArhielTableNav page={[page, setpage]} jumlahPage={getJumlahPage()} />
                    </div>
                </div>
            </Context>
        </>
    )
}

export default InputFaktur
