import React, { useState, useEffect } from 'react'
import Context from './Context'
import NamaLevelUser from '../function/NamaLevelUser'
import { ipserver as SERVER } from '../variable';
import { Line } from 'react-chartjs-2';
import axios, * as others from 'axios';
import { ArhielTglLessDay } from '../function/ArhielTgl';
import { NamaBulan } from '../function/ArhielMonth';
const UserSetting = () => {
    const nama = localStorage.getItem("nama")
    const [bulan, setbulan] = useState(new Date().getMonth() + 1)
    const [tahun, settahun] = useState(new Date().getFullYear())
    const [data, setdata] = useState([])
    const [result, setresult] = useState([])
    useEffect(() => {
        // alert(SERVER + "/kas/grafik/" + bulan + "/" + tahun)
        axios.get(SERVER + "/kas/grafik/" + bulan + "/" + tahun, { withCredentials: true }).then((res) => {

            setdata(res.data)
        })
    }, [])
    useEffect(() => {
        var endmonth = new Date(Date.UTC(tahun, bulan, 0));
        var firstmonth = new Date(Date.UTC(tahun, bulan - 1, 1));

        const tempresult = {
            labels: [],
            datasets: [
                {
                    id: 1,
                    label: 'Toli Toli',
                    borderColor: "#FF0000",
                    fill:true,
                    backgroundColor:"rgba(245, 0,0,0.31)",
                    data: [],
                },
                {
                    id: 2,
                    label: 'Buol',
                    fill: true,
                    borderColor: "#FF6600",
                    backgroundColor:"rgba(255,126, 0, 0.3)",
                    data: [],
                },
            ],
        };
        for (var d = firstmonth; d <= endmonth; d.setDate(d.getDate() + 1)) {
            tempresult.labels.push(ArhielTglLessDay(new Date(d)))

            if (data.datas) {

                const getPenjualan = data.datas.find(x => new Date(d).getDate() === new Date(x._id.tglTransaksi).getDate() && x._id.kota === "toli-toli")
                const penjualan = getPenjualan ? getPenjualan.totalBayar : 0;
                tempresult.datasets[0].data.push(penjualan)

                const getPenjualan2 = data.datas.find(x => new Date(d).getDate() === new Date(x._id.tglTransaksi).getDate() && x._id.kota === "buol")
                const penjualan2 = getPenjualan2 ? getPenjualan2.totalBayar : 0;
                tempresult.datasets[1].data.push(penjualan2)
            }
        }
        setresult(tempresult)
    }, [data])
    return (
        <Context>

            <h1 className='text-center text-red-600 font-bold text-xl'>Selamat Datang, {nama}</h1>
            <h1 className='text-center text-gray-600 font-bold text-sm'>Laporan Penjualan {NamaBulan[bulan - 1]} {tahun}</h1>
            <Line
                datasetIdKey='id'
                data={result}
            />
            <table hidden className='bg-gray-400 w-full md:w-1/3'>
                <tr className='tr'>
                    <td className='td'>Nama Lengkap</td>
                    <td className='td'>:</td>
                    <td className='td'>{localStorage.getItem("nama")}</td>
                </tr>
                <tr className='tr'>
                    <td className='td'>Username</td>
                    <td className='td'>:</td>
                    <td className='td'>{localStorage.getItem("username")}</td>
                </tr>
                <tr className='tr'>
                    <td className='td'>Status User</td>
                    <td className='td'>:</td>
                    <td className='td'>{NamaLevelUser(localStorage.getItem('level'))}</td>
                </tr>
            </table>
        </Context>
    )
}

export default UserSetting
