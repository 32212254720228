import React, {useState} from 'react'

const ArhielTextBox = props => {

    return (
        <input className={"shadow text-sm appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "} 
        value={props.value}
        onChange={props.onChange} 
        type={props.type} 
        placeholder={props.placeholder} />
                        
    )
}

export default ArhielTextBox
