import React from 'react'

const ArhielSelect = props => {
    return (
        <select value={props.value} onChange={props.onChange} className={"shadow bg-white text-sm appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "}> 
            <option disabled className="font-bold text-sm italic text-gray-400" value="">{props.placeholder} : </option>
                            
            {props.children}
        </select>
    
    )
}

export default ArhielSelect
