import React, { useState, useEffect } from 'react'
import ModalCover from '../ModalCover'

import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable';


const ModalAddNew = props => {
    const [visible, setvisible] = props.visible
    const filed = props.field;


    const [keterangan, setketerangan] = useState('')
    const [setPage, jumlahPage] = props.paging
    // const [arhiel, setarhiel] = useState(true)

    const [data, dispatch] = props.dispatch

    const [tempData, settempData] = useState({})

    const link = props.link

    useEffect(() => {
        if(visible)
        {
            const initdata = {}
            for (let i = 0; i < filed.length; i++) {
                initdata[filed[i].name] = ""
            }
            settempData(initdata)
        }
    }, [visible])

    const saveHandler = async () => {
        // console.log(tempData)
        setketerangan("")
        let result = true;
        for (let i = 0; i < filed.length; i++) {
            // updateket()
            const val = tempData[filed[i].name]
            if (filed[i].require && (!val || val === "")) {

                setketerangan("Masukan " + filed[i].title + " Dengan Benar")
                result = false;
                break;
            }

        }

        if (result) {
            const tempid = 1;
            // alert('a')
            props.loading(true)
            setvisible(false)
            axios.post(SERVER + link, tempData, {withCredentials:true}).then(res => {

                props.loading(false)
                const statusCode = res.status
                if (statusCode == 200) {
                    dispatch({ type: 'add', data: { ...tempData, _id: res.data._id } })
                    settempData({})
                    setPage(jumlahPage)
                } else {
                    alert("Terjadi Massalah Error Code " + statusCode)
                }
            }).catch(err => {
                console.log(err)
            })

            // console.log(data)

            // setvisible(false)
        }
    }
    useEffect(() => {
        // alert('a')
    }, [])
    const changeHandler = (val) => {

        // console.log(val)
        setketerangan("")
        // const result = tempData;
        // result[val.target.name] = val.target.value
        settempData({
            ...tempData,
            [val.target.name]: val.target.value,
        })
        // console.log(result)
    }
    const buatinput = (type, nama, title, item, spesial) => {

        switch (type) {
            case "text":
                if (spesial === "npwp") {
                    return (
                        <>
                            <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                            <button onClick={()=>settempData({
                                ...tempData,
                                [nama]: "000000000000000",
                            })} className="bg-red-500 mr-1 text-xs hover:bg-red-700 text-white italic py-1 px-2 border border-red-500 rounded">Klik Disini Jika Kosong</button>

                        </>)
                } else if (spesial === "nik") {
                    return (
                        <>
                            <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                            <button onClick={()=>settempData({
                                ...tempData,
                                [nama]: "0000000000000000",
                            })} className="bg-red-500 mr-1 text-xs hover:bg-red-700 text-white italic py-1 px-2 border border-red-500 rounded">Klik Disini Jika Kosong</button>

                        </>)
                } else {

                    return <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                }
            case "password":
                return <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" placeholder={title} />
            case "select":
                const resitem = [];
                item.map((value, index) => {
                    resitem.push(<option value={value.value}>{value.title}</option>)
                })
                return <select name={nama} value={tempData[nama]} onChange={(val) => changeHandler(val)} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option disabled selected value="" className="font-bold italic text-xs">Pilih {title}</option>
                    {resitem}
                </select>
            default:
                return false;
        }
    }
    return (
        <ModalCover visible={visible} width="1/3" bg="white" judul="Tambah Data baru">
            <div>
                {
                    filed.map((item, index) => {

                        return <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                {item.title}
                            </label>
                            {
                                buatinput(item.type, item.name, item.title, item.item, item.spesial)
                            }
                        </div>
                    })
                }
            </div>

            {keterangan ?

                <div className={"bg-red-400 flex items-center justify-center align-middle rounded-md"}>
                    <label className="text-white font-bold m-1 text-xs">{keterangan}</label>
                </div> : null

            }

            {/* {
                arhiel ?
                <div>aaaa</div> : null
            } */}
            <div className="flex  items-center justify-center pt-1">
                <button onClick={saveHandler} className="bg-red-500 mr-1 text-sm hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">Simpan</button>

                <span onClick={() => setvisible(false)} className="text-xs font-bold ml-3 cursor-pointer text-red-800 active:text-red-500">Batal</span>
            </div>
        </ModalCover >
    )
}

export default ModalAddNew
