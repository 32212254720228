import React from "react";

import * as FaIcons from "react-icons/fa"; // disini diibuh dari {iconName} jadi * as FaIcons
import * as MdIcons from "react-icons/md";

export const SideBarData = [
    {
        title: "Home",
        path: "/",
        icon: (color) => { return <FaIcons.FaHome color={color} /> },
        cName: "nav-text"
    },
    {
        title: "Input Data",
        path: "/input",
        icon: (color) => { return <MdIcons.MdInput color={color} /> },
        cName: "nav-text"
    },
    {
        title: "Report",
        path: "/report",
        icon: (color) => { return <MdIcons.MdReport color={color} /> },
        cName: "nav-text"
    },
]

export const DataSideBar = [
    {
        title : "Data Oli",
        path : '/oli',
        child : [
            {
                type: "tunggal",
                title: "Home",
                path: "/",
                icon: (color) => { return <FaIcons.FaHome size={15} color={color} /> },
            },
            {
                type: "tunggal",
                title: "Daftar Barang",
                path: "/databarang",
                icon: (color) => { return <FaIcons.FaListAlt size={15} color={color} /> },
            },
            {
                type: "tunggal",
                title: "Daftar Pelanggan",
                path: "/pelanggan",
                icon: (color) => { return <FaIcons.FaUser size={15} color={color} /> },
            },
            {
                type: "group",
                title: "Input Transaksi",
                path: "/",
                icon: (color) => { return <FaIcons.FaMoneyBillAlt size={15} color={color} /> },
                child: [
                    {
                        title: "Pembelian",
                        path: "/pembelian",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                    {
                        title: "Penjualan",
                        path: "/penjualan",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    }
                ]
            },
            {
                type: "group",
                title: "Data",
                path: "/",
                icon: (color) => { return <FaIcons.FaServer size={15} color={color} /> },
                child: [
                    {
                        title: "Data Pembelian",
                        path: "/data-pembelian",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                    {
                        title: "Data Penjualan",
                        path: "/data-penjualan",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    }
                ]
            },
            {
                type: "group",
                title: "Laporan",
                path: "/",
                icon: (color) => { return <FaIcons.FaBoxes size={15} color={color} /> },
                child: [
                    {
                        title: "Laporan Stok",
                        path: "/stok/toli-toli",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                    {
                        title: "Laporan Kas",
                        path: "/kas/toli-toli",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                   
                ]
            },
            
        ]
    },
    {
        title : "Data Pajak",
        path : '/pajak',
        child : [
            {
                type: "tunggal",
                title: "Home",
                path: "/",
                icon: (color) => { return <FaIcons.FaHome size={15} color={color} /> },
            },
            {
                type: "group",
                title: "Laporan",
                path: "/",
                icon: (color) => { return <FaIcons.FaBoxes size={15} color={color} /> },
                child: [
                    {
                        title: "Laporan Stok",
                        path: "/stok/toli-toli",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                    {
                        title: "Laporan Kas",
                        path: "/kas/toli-toli",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                   
                ]
            },
            {
                type: "group",
                title: "Faktur Pajak",
                path: "/",
                icon: (color) => { return <FaIcons.FaTeeth size={15} color={color} /> },
                child: [
                    {
                        title: "Input Faktur",
                        path: "/input",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                    {
                        title: "Export CSV",
                        path: "/export",
                        icon: (color) => { return <FaIcons.FaCaretRight color={color} /> },
                        cName: "nav-text"
                    },
                   
                ]
            },
            
        ]
    }
    
]