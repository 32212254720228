import React, { useReducer, useState, useEffect } from 'react'
import Context from '../Context'
import TablePage from '../../arhiel/pages/TablePage'


import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            // console.log( " asddsa", action.data)
            return [...state, action.data]
        case 'delete':
            // console.log(action.data)
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        case 'edit':
            state[action.index] = action.data;

            return state;
        case 'init':

            return action.data
        default:
            return state;
    }
}

const Pelanggan = () => {
    const field = [
        {
            title: 'Kode Pelanggan',
            type: 'text',
            name: 'kodePelanggan',
            require : true
        },
        {
            title: 'Nama Pelanggan',
            type: 'text',
            name: 'namaPelanggan',
            require : true
        },
        {
            title: 'Alamat Pelanggan',
            type: 'text',
            name: 'alamatPelanggan',            
            require : false
        },
        {
            title: 'NPWP Pelanggan',
            type: 'text',
            name: 'npwpPelanggan',
            spesial : 'npwp',
            require : true
        },
        {
            title: 'NIK Pelanggan',
            type: 'text',
            name: 'nikPelanggan',
            spesial : 'nik',
            require : true
        }
    ]
   
    // console.log(tempdata)
    // const tempdata = [];
    const [data, dispatch] = useReducer(reducer, []);
    const [initLoading, setInitLoading] = useState(true)
    useEffect(() => {
        setInitLoading(true)
        axios.get(SERVER + '/pelanggan', {withCredentials:true}).then(res => {
            setInitLoading(false)
            dispatch({ type: 'init', data: res.data })
        }).catch(err => {
            console.log(err)
        })

    }, [])
    return (
        <Context>
           
            <TablePage
                link="/pelanggan"
                judul="Daftar Pelanggan"
                data={[data, dispatch]}
                initLoading={initLoading}
                show={10}
                field={field} />
        </Context>
    )
}

export default Pelanggan
