import React from 'react'

const JudulPage = props => {
    return (
        <h1 className="border-b-2 border-double border-red-400 mb-2 py-2 font-bold text-red-900">
            {props.children} 
        </h1>
                
    )
}

export default JudulPage
