import React, { useEffect, useState, useContext } from 'react'
import { Outlet} from 'react-router-dom'
import SideBar from '../components/sideBar/SideBar'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../variable';
import { AuthContext } from '../App'
import LoadingVerifikasi from '../arhiel/components/LoadingVerifikasi';

const Pajak = props => {
    const [verifikasi, setverifikasi] = useState(false)
    const [stateAuth, dispatchAuth] = useContext(AuthContext)
    useEffect(() => {
        axios.get(SERVER + "/cektoken", { withCredentials: true }).then(res => {
            if (res.data.error === 0) {
                dispatchAuth({
                    type: "LOGIN",
                    payload: res.data.data
                })
                setverifikasi(true)
            } else {
                dispatchAuth({
                    type: "LOGOUT"
                })
                // navigate("/", { replace: true });

            }
        }).catch(error => {
            console.log(error)
            alert("Terjadi Masalah, Silahkan Ulangi Beberapa Saat Lagi")
        })
    }, [])
    return (

        <div className='h-full'>
            {
                verifikasi ?
                    <>
                        <SideBar />
                        <div className="align-middle justify-center p-2 absolute pt-14 left-0 right-0 min-h-full bg-red-200 md:left-48">
                            <Outlet />
                        </div>
                        
                    </> :
                    <LoadingVerifikasi />

            }

        </div>
    )
}

export default Pajak
