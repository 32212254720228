import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import * as FaIcons from "react-icons/fa"; // disini diibuh dari {iconName} jadi * as FaIcons
import { DataSideBar } from './SideBarData';
import { AuthContext } from '../../App';
const SideBar = props => {
    
    const [stateAuth, dispatchAuth] = useContext(AuthContext)
    const location = useLocation()
    const [selected, setselected] = useState("/")
    const [selectedData, setselectedData] = useState("/oli")
    const [sideBar, setsideBar] = useState(0)

    const showSidebar = () => {
        setsideBar(!sideBar)
    }

    useEffect(() => {
        const path = location.pathname.split("/")
        setselected("/" + path[2])
        setselectedData("/" + path[1])
        //    alert(props.params)
    }, [])

    const getlistmenu = () => {
        let data = DataSideBar.find(data => data.path === selectedData)
        // console.log(data.child)
        return data.child
    }
    const logout = () => {
        dispatchAuth({
            type: "LOGOUT"
        })
    }
    // props.match.params
    return (
        <>
            <div className="w-full items-center flex justify-between fixed left-0 right-0 top-0 z-40 bg-red-900 border-red-400 border-double border-b-2 flex-shrink shadow-md p-2">
                <Link to="#" className="visible md:invisible">
                    <FaIcons.FaBars size={20} color="#FFFFFF" onClick={showSidebar} />
                </Link>

                <ul className="flex bg-red-100 rounded-3xl px-2 py-1">
                    <li onClick={logout}
                        className={"text-xs flex justify-center items-center cursor-pointer text-red-500 font-bold rounded-3xl px-4 py-1 hover:text-red-600"}>

                        <FaIcons.FaSignOutAlt size={15} className='mr-1' color="#FF0000" onClick={showSidebar} />
                        Logout
                    </li>
                </ul>
            </div>
            <nav className={"fixed z-10 md:z-50 left-0 pt-2 border-r-2 border-red-400  top-12 md:top-0 md:bottom-0 bg-red-700 p-2 w-full md:w-48 " + (sideBar ? "visible" : "invisible") + " md:visible"}>
                <ul className="nav-menu-items">

                    {getlistmenu().map((item, index) => {
                        return (
                            item.type === 'tunggal' ?

                                <Link to={selectedData + item.path} onClick={() => { setsideBar(false); setselected(item.path) }} className="  w-full flex items-center ">
                                    <li key={index} className={"active:bg-red-900 hover:rounded-md flex hover:bg-red-500 hover:border-red-300  w-full py-2 px-2 mb-2 align-middle " + (item.path === selected ? "bg-red-100 rounded-md" : "")}>
                                        {item.icon(item.path === selected ? "#FF0000" : "#FFFFFF")}
                                        <span className={"font-serif text-xs ml-2 font-bold " + (item.path === selected ? "text-red-600" : "text-white ")}>{item.title}</span>
                                    </li>
                                </Link>
                                :
                                <ul className="px-0 mx-0">

                                    <div className="text-white mb-2 w-full px-2 flex items-center text-sm font-bold">
                                        {item.icon("#FFFFFF")}
                                        <span className="ml-2">{item.title}</span>
                                    </div>
                                    {
                                        item.child.map((subitem, subindex) => {
                                            const itempath = "/" + subitem.path.split("/")[1];
                                            console.log(itempath)
                                            return (
                                                <Link to={selectedData + subitem.path} onClick={() => { setsideBar(false); setselected(itempath); }} className={"ml-7 mb-1 rounded-md  flex hover:bg-red-500 " + (selected === itempath ? "bg-red-100" : "")}>
                                                    <li key={index} className={"flex ml-0 w-full p-1.5 items-center "}>
                                                        {subitem.icon(itempath === selected ? "#FF0000" : "#FFFFFF")}
                                                        <span className={"font-serif text-xs ml-1 font-bold " + (itempath === selected ? "text-red-600" : "text-white ")}>{subitem.title}</span>
                                                    </li>
                                                </Link>
                                            )
                                        })
                                    }
                                </ul>

                        )
                    })}
                </ul>
            </nav>
        </>
    )
}

export default SideBar
