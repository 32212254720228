import React from 'react'
import { Dots } from "react-activity";

import "react-activity/dist/Dots.css";
import ModalCover from './ModalCover'

const Confirm = props => {
    const [visible, setVisible] = props.visible
    const yes = () => {
        setVisible(false)
        props.yes()
    }
    return (
        <ModalCover visible={visible} width="80">
            <div className="align-middle justify-center text-center">
                <span className="text-sm font-bold text-gray-700 ">{props.title}</span>
                <div className="p-2">
                    <button onClick={yes} className="bg-red-500 mr-1 text-xs hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">{props.titleyes}</button>
                    <span onClick={() => setVisible(false)} className="text-xs font-bold ml-3 cursor-pointer text-red-800 active:text-red-500">Batal</span>
                </div>
            </div>
        </ModalCover>
    )
}

export default Confirm
