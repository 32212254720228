import React, { useReducer, useEffect, useState } from 'react'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable';
import Context from '../../Context';
import { Rp } from '../../../function/Rupiah';
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable';
import Loading from '../../../arhiel/components/Loading';
import JudulPage from '../../../arhiel/components/JudulPage';
import { ArhielTgl } from '../../../function/ArhielTgl';
import NomorFaktur from '../../../function/NomorFaktur';
import Confirm from '../../../arhiel/components/Confirm';
import ArhielNavigasi from '../../../arhiel/components/ArhielNavigasi';
import ArhielTableNav from '../../../arhiel/components/TablePage/ArhielTableNav';
import JudulTable from '../../../arhiel/components/JudulTable';

import * as FaIcons from "react-icons/fa";
import { NamaBulan } from '../../../function/ArhielMonth';
import ArhielPDFView from '../../../arhiel/components/ArhielPDFView';
import PDFTable from '../../../components/PDFTable';
import DownloadExcel from '../../../arhiel/components/DownloadExcel';
import ModalWindow from '../../../arhiel/components/ModalWindow';
import DetailPenjualan from './DetailPenjualan';
import EditPenjualan from './EditPenjualan';

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.data
        case 'delete':
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        default:
            return state;
    }
}


const DataPenjualan = props => {

    const [data, dispatch] = useReducer(reducer, [])
    const [loadingVisible, setloadingVisible] = useState(true)


    const [typeData, settypeData] = useState("harian")
    const [valueData, setvalueData] = useState(new Date())

    useEffect(() => {

        setloadingVisible(false)
        getData(typeData, valueData)
    }, [])

    const [judulTable, setjudulTable] = useState("")
    const getData = (type, value) => {
        setloadingVisible(true)
        setsearchText("")
        setpage(1)
        let link = "";
        type === "harian" ? link = "data/" + value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate()
            : link = `${value.bulan}/${value.tahun}`
        // alert(SERVER + "oli/penjualan/" +link)
        axios.get(SERVER + "/penjualan/" + link, { withCredentials: true }).then((res) => {
            if (type === "harian") {
                setprintjudulTable(ArhielTgl(value))
                setjudulTable(<><p>Data Penjualan</p><p>{ArhielTgl(value)}</p></>)
                setnamaFileExport("Data Penjualan " + ArhielTgl(value))
            } else {
                setjudulTable(<><p>Data Penjualan</p><p>Bulan {NamaBulan[value.bulan - 1]} {value.tahun}</p></>)
                setnamaFileExport("Data Penjualan Bulan " + NamaBulan[value.bulan - 1] + " " + value.tahun)
                setprintjudulTable("Bulan " + NamaBulan[value.bulan - 1] + " " + value.tahun)
            }
            setloadingVisible(false)
            dispatch({ type: 'init', data: res.data })
            // console.log(res.data)
        })
    }

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", 'Kota', "Tanggal", "Nomor Faktur", "Pelanggan", "Jumlah Bayar", "Jatuh Tempo"
        ]
        const value = [];
        filterData().slice((page - 1) * jumlahShow, (page - 1) + ((jumlahShow - 1) * page) + 1).map((item, index) => {
            const isivalue = []
            isivalue.push({ value: index + 1, align: 'text-center' })
            isivalue.push({ value: item.kota, align: 'text-center' })
            isivalue.push({ value: ArhielTgl(item.tglTransaksi), align: 'text-center' })
            isivalue.push({ value: NomorFaktur(item.nomorFaktur, item.tglTransaksi), align: 'text-center' })
            isivalue.push({ value: item.dataPelanggan.namaPelanggan, align: 'text-left' })
            isivalue.push({ value: Rp(item.totalBayar, true), align: 'text-right' })
            isivalue.push({ value: ArhielTgl(item.tglJatuhTempo), align: 'text-center' })
            value.push({ key: item._id, value: isivalue })
            return false
        })
        json['value'] = value;
        json['property'] = {

        }
        return json;
    }
    const detailHandler = (key) => {
        setkeySelected(key)
        setwindowDetail(true)
    }
    const editHandler = (key) => {
        setkeySelected(key)
        setwindowEdit(true)
    }


    const [confirmDelete, setconfirmDelete] = useState(false)
    const [keySelected, setkeySelected] = useState("")
    const [modalLoading, setmodalLoading] = useState(false)
    const yesDeleteHandler = () => {
        setconfirmDelete(false)
        setmodalLoading(true)
        axios.delete(SERVER + "/penjualan/" + keySelected, {withCredentials:true}).then(res => {

            setmodalLoading(false)
            const statusCode = res.status
            if (statusCode == 200) {
                dispatch({ type: 'delete', data: keySelected })


            } else {
                alert("Terjadi Massalah Error Code " + statusCode)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const deleteHandler = key => {
        setkeySelected(key)
        setconfirmDelete(true)
    }

    //untuk masalah page


    const [searchText, setsearchText] = useState("")
    const jumlahShow = 10;
    const [page, setpage] = useState(1)
    const filterData = () => {
        // return data.filter(v => v.namaPelanggan.toLowerCase().includes(searchText.toLowerCase()))
        console.log(data)
        return data.filter(item => {
            return Object.keys(item).some(key => item[key].toString().toLowerCase().search(searchText.toLowerCase()) !== -1);
        })
        // return Object.keys(item).some(key => item[key].toString().search(searchValue) !== -1);
    }

    const getJumlahPage = () => {
        if (filterData().length === 0) {
            return 1;
        } else {

            const sisa = filterData().length % jumlahShow
            if (sisa === 0) {
                return Math.floor(filterData().length / jumlahShow)
            } else {
                return (Math.ceil(filterData().length / jumlahShow))
            }
        }
    }

    //untuk masalah header

    const [namaFileExport, setnamaFileExport] = useState("")
    const headerExel = [

        {
            label: "No",
            value: "nomor",
        },
        {
            label: "Kota",
            value: "kota",
        },
        {
            label: "Tanggal",
            value: "tglTransaksi",
        },
        {
            label: "Nomor Faktur",
            value: "nomorFaktur",
        },
        {
            label: "Nama Pelanggan",
            value: "namaPelanggan",
        },
        {
            label: "Jumlah Bayar",
            value: "totalBayar",
        },
        {
            label: "Jatuh Tempo",
            value: "tglJatuhTempo",
        }
    ]

    const isiExcel = () => {
        const result = [];
        data.map((item, index) => {
            const isi = {}
            isi['nomor'] = index + 1
            isi['kota'] = item.kota
            isi['tglTransaksi'] = ArhielTgl(item.tglTransaksi)
            isi['nomorFaktur'] = NomorFaktur(item.nomorFaktur, item.tglTransaksi)
            isi['namaPelanggan'] = item.dataPelanggan.namaPelanggan
            isi['totalBayar'] = item.totalBayar
            isi['tglJatuhTempo'] = ArhielTgl(item.tglJatuhTempo)
            result.push(isi)
        })
        return result;
    }
    const [printjudulTable, setprintjudulTable] = useState("")
    const [pdfViewr, setpdfViewr] = useState(false)
    const pdfHandler = () => {
        setpdfViewr(true)

    }
    const [windowDetail, setwindowDetail] = useState(false)
    const [windowEdit, setwindowEdit] = useState(false)
    const editFinishHandler = () => {
        setwindowEdit(false)
        getData(typeData, valueData)
    }
    return (
        <>
            <ModalWindow visible={[windowDetail, setwindowDetail]} judul="Detail Penjualan">
                <DetailPenjualan objectId={keySelected} />

            </ModalWindow>
            <ModalWindow visible={[windowEdit, setwindowEdit]} judul="Edit Penjualan">
                <EditPenjualan objectId={keySelected} editFinish={editFinishHandler} />
            </ModalWindow>
            <ArhielPDFView visible={[pdfViewr, setpdfViewr]}>
                <PDFTable
                    namaData="Data Penjualan"
                    judul={printjudulTable}
                    label={headerExel}
                    data={isiExcel()} />
            </ArhielPDFView>
            <Loading visible={[modalLoading, setmodalLoading]} />
            <Confirm
                visible={[confirmDelete, setconfirmDelete]}
                title="Hapus Data penjualan?"
                titleyes="Hapus"
                yes={yesDeleteHandler} />
            <Context>

                <div className="bg-white overflow-visible min-h-full rounded-lg shadow-md mr-2 mb-4">
                    <JudulPage>
                        Data penjualan
                    </JudulPage>
                    <ArhielNavigasi
                        onClick={(type, value) => { settypeData(type); setvalueData(value); getData(type, value) }}
                    />

                    <div className="p-0 pb-2 min-h-full">
                        <div>
                            <JudulTable>{judulTable}</JudulTable>
                        </div>
                        <div className="md:flex justify-between bg-red-200 p-2 align-middle items-center">
                            <div className="w-full pb-2 md:pb-0 items-center justify-between flex md:items-start md:justify-start">
                                <DownloadExcel
                                    label={headerExel}
                                    fileName={namaFileExport}
                                    data={isiExcel()} />
                                <button onClick={pdfHandler} className=" bg-red-500 mr-1 text-xs flex flex-1 md:flex-none  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaFilePdf color="#FFFFFF" />
                                    <span className="ml-2">Print PDF</span>
                                </button>
                            </div>


                            <div className="flex flex-1 justify-between items-center">
                                <button onClick={() => getData(typeData, valueData)} className=" bg-red-500 mr-1 text-xs flex  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaSync className="mr-1" />
                                    <span>Refresh</span>
                                </button>
                                <div className="flex w-min py-1 px-2 bg-white shadow border rounded items-center">
                                    <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                                    <FaIcons.FaSearch color="#FF6600" />
                                </div>
                            </div>
                        </div>
                        <ArhielTable
                            data={dataTable()}
                            state={[data, dispatch]}
                            navrow={true}
                            detailButton={true}
                            detailHandler={detailHandler}
                            editHandler={editHandler}
                            initLoading={loadingVisible}
                            deleteHandler={deleteHandler}
                        // footer={footerTable()} 
                        />
                        <ArhielTableNav page={[page, setpage]} jumlahPage={getJumlahPage()} />
                    </div>
                </div>
            </Context>
        </>
    )
}

export default DataPenjualan
