import React from 'react'

const ArhielLabel = props => {
    return (
        <span className="whitespace-nowrap block mb-2 md:mb-0 text-gray-700 text-xs font-bold md:mr-3 md:w-1/3">
            {props.children}
        </span>
    )
}

export default ArhielLabel
