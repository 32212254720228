import React, { useReducer, createContext, useState } from 'react';

import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

import Login from './screen/Login';
import Home from './screen/Home';
import MasterUser from './screen/MasterUser';
import Pajak from './screen/Pajak';
import DataBarang from './screen/oli/DataBarang';
import Pelanggan from './screen/oli/Pelanggan';
import PembelianOli from './screen/oli/Pembelian';
import DataPembelian from './screen/oli/pembelian/DataPembelian';
import DetailPembelian from './screen/oli/pembelian/DetailPembelian';
import PenjualanOli from './screen/oli/Penjualan';
import DataPenjualan from './screen/oli/penjualan/DataPenjualan';
import DataStok from './screen/oli/stok/DataStok';
import Stok from './screen/oli/Stok';
import Cobax from './screen/oli/Cobax';
import TransaksiStok from './screen/oli/stok/TransaksiStok';
import DataArusKas from './screen/oli/kas/DataArusKas';
import Kas from './screen/oli/Kas';
import InputFaktur from './screen/pajak/InputFaktur';
import ExportCSV from './screen/pajak/ExportCSV';
import UserSetting from './screen/UserSetting';
// import Stok from './screen/oli/DataStok';

export const AuthContext = createContext();
const initialStateAuth = {
  login: localStorage.getItem("login") || false,
  username: localStorage.getItem("username"),
  nama: localStorage.getItem("nama"),
  level: localStorage.getItem("level")
}
const authReducer = (state, action) => {

  switch (action.type) {
    case "LOGIN":
      localStorage.setItem('login', true)
      localStorage.setItem("username", action.payload.username)
      localStorage.setItem("nama", action.payload.nama)
      localStorage.setItem("level", action.payload.level)
      return { ...state, login: true, username: action.payload.username, nama: action.payload.nama, level: action.payload.level };
    case "LOGOUT":
      localStorage.clear();
      return { ...state, login: false, username: "", nama: "", level: "" };
    default:
      return state
  }
}
function App() {
  const [state, dispatch] = useReducer(authReducer, initialStateAuth)

  const handleLogin = () => {

    if (state.login) {
      switch (parseInt(state.level)) {
        case 0:
          return <Navigate to="/master" />
        case 1:
          return <Navigate to="/oli" />
        case 2:
          return <Navigate to="/pajak" />
        default:
          return <Login />
      }
    } else {
      return <Login />
    }
  }

  const handlePage = () => {

    if (state.login) {
      switch (parseInt(state.level)) {
        case 0:
          return <Navigate to="/master" />
        case 1:
          return <Navigate to="/oli" />
        case 2:
          return <Navigate to="/pajak" />
        default:
        // return <Navigate to="/" />
      }
    } else {
      return <Navigate to="/" />
    }
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider value={[state, dispatch]}>
        <Routes>
          <Route path="/" element={handleLogin()} />

          <Route
            path="master"
            element={
              state.login && parseInt(state.level) === 0 ? (
                <MasterUser />
              ) : handlePage()
            }
          />
          <Route
            path="oli"
            element={
              state.login && parseInt(state.level) === 1 ? (
                <Home />
              ) : handlePage()
            }
          >
            <Route path="" element={<UserSetting />} />
            <Route path="databarang" element={<DataBarang />} />
            <Route path="pelanggan" element={<Pelanggan />} />
            <Route path="pembelian" element={<PembelianOli />} />
            <Route path="data-pembelian" element={<DataPembelian />} />
            <Route path="penjualan" element={<PenjualanOli />} />
            <Route path="data-penjualan" element={<DataPenjualan />} />
            <Route path="stok" element={<Stok />}>
              <Route path="" element={<DataStok />} />
              <Route path=":kota"> {/*kalau element nya diisi artinya seluruh child muncul element itu... bingung juga saya*/}
                <Route path="" element={<DataStok />} />
                <Route path=":idBarang/:bulan/:tahun" element={<TransaksiStok />} />
              </Route>
            </Route>
            <Route path="kas" element={<Kas />}>
              <Route path="" element={<DataArusKas />} />
              <Route path=":kota"> {/*kalau element nya diisi artinya seluruh child muncul element itu... bingung juga saya*/}
                <Route path="" element={<DataArusKas />} />
                
              </Route>
            </Route>
            
          </Route>
          <Route
            path="pajak"
            element={
              state.login && parseInt(state.level) === 2 ? (
                <Pajak />
              ) : handlePage()
            }
          >
            <Route path="" element={<UserSetting />} />
            <Route path="stok" element={<Stok />}>
              <Route path="" element={<DataStok />} />
              <Route path=":kota"> {/*kalau element nya diisi artinya seluruh child muncul element itu... bingung juga saya*/}
                <Route path="" element={<DataStok />} />
                <Route path=":idBarang/:bulan/:tahun" element={<TransaksiStok />} />
              </Route>
            </Route>
            <Route path="kas" element={<Kas />}>
              <Route path="" element={<DataArusKas />} />
              <Route path=":kota"> {/*kalau element nya diisi artinya seluruh child muncul element itu... bingung juga saya*/}
                <Route path="" element={<DataArusKas />} />
                
              </Route>
            </Route>
            <Route path="input" element={<InputFaktur />} />
            <Route path="export" element={<ExportCSV />} />
          </Route>




        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
