import React, { useReducer, useState, useEffect } from 'react'

import * as FaIcons from "react-icons/fa";
import ArhielTable from '../components/TablePage/ArhielTable';
import ArhielTableNav from '../components/TablePage/ArhielTableNav';
import ModalAddNew from '../components/TablePage/ModalAddNew';
import Confirm from '../components/Confirm';
import ModalEdit from '../components/TablePage/ModalEdit';
import Loading from '../components/Loading';

import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable';


const TablePage = props => {

    const jumlahShow = props.show;
    const [page, setpage] = useState(1)
    const [data, dispatch] = props.data;

    const [searchText, setsearchText] = useState("")

    const link = props.link

    const filterData = () => {
        // return data.filter(v => v.namaPelanggan.toLowerCase().includes(searchText.toLowerCase()))
        return data.filter(item => {
            return Object.keys(item).some(key => item[key].toString().toLowerCase().search(searchText.toLowerCase()) !== -1);
        })
        // return Object.keys(item).some(key => item[key].toString().search(searchValue) !== -1);
    }


    const dataTable = () => {
        const json = {}
        const title = ["No. "]
        props.field.map((item, index) => {
            if(item.type !== "password")
            {
                title.push(item.title)
            }
        })
        json['title'] = title
        const value = [];
        // console.log("Dari " + ((page - 1) * jumlahShow) + " Sampai " + ((page - 1) + ((jumlahShow - 1) * page) + 1))
        filterData().slice((page - 1) * jumlahShow, (page - 1) + ((jumlahShow - 1) * page) + 1).map((item, index) => {
            const isivalue = []

            isivalue.push({ value: (index + 1) + ((page - 1) * jumlahShow), align: 'text-center' })
            for (const isiitem in item) {
                if (isiitem !== "_id" && isiitem !== "password") {
                    isivalue.push({ value: item[isiitem], align: 'text-left' })
                }
            }

            value.push({ key: item._id, value: isivalue })
            return false
        })
        json['value'] = value;

        json['property'] = {

        }
        return json;
    }
    useEffect(() => {

    }, [])

    const getJumlahPage = () => {
        if (filterData().length === 0) {
            return 1;
        } else {

            const sisa = filterData().length % jumlahShow
            if (sisa === 0) {
                return Math.floor(filterData().length / jumlahShow)
            } else {
                return (Math.ceil(filterData().length / jumlahShow))
            }
        }
    }
    const [vmodaAddNew, setvmodaAddNew] = useState(false)
    const [confirmVisible, setconfirmVisible] = useState(false)
    const [keySelected, setkeySelected] = useState(0)
    const deleteHandler = key => {
        setconfirmVisible(true)
        setkeySelected(key)
    }
    const yesHadler = () => {
        setloadingVisible(true)
        setconfirmVisible(false)
        axios.delete(SERVER + link + '/' + keySelected, {withCredentials:true}).then(res => {

            setloadingVisible(false)
            const statusCode = res.status
            if (statusCode == 200) {
                dispatch({ type: 'delete', data: keySelected })
               
            } else {
                alert("Terjadi Massalah Error Code " + statusCode)
            }
        }).catch(err => {
            console.log(err)
        })
        
    }

    const editHandler = key => {
        setkeySelected(key)
        setvmodalEdit(true)
    }

    const [vmodalEdit, setvmodalEdit] = useState(false)
    const [loadingVisible, setloadingVisible] = useState(false)
    return (
        <>
            <Loading visible={[loadingVisible, setloadingVisible]} />
            <ModalAddNew link={link} loading={setloadingVisible} paging={[setpage, getJumlahPage()]} visible={[vmodaAddNew, setvmodaAddNew]} dispatch={[data, dispatch]} field={props.field} />
            <ModalEdit link={link}  loading={setloadingVisible} selected={keySelected} visible={[vmodalEdit, setvmodalEdit]} dispatch={[data, dispatch]} field={props.field} />

            <Confirm
                visible={[confirmVisible, setconfirmVisible]}
                title="Yakin Ingin Menghapus Data Ini ?"
                titleyes="Hapus"
                yes={yesHadler} />
            <div>
                <h1 className="border-b-2 border-double border-red-400 mb-2 py-2 font-bold text-red-900">{props.judul} </h1>
                <div className="flex justify-between align-middle items-center">
                    <button onClick={() => { setvmodaAddNew(true) }} className=" bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                        <FaIcons.FaPlus className="mr-1" />
                        <span>Tambah Baru</span>
                    </button>
                    <div className="flex py-1 px-2 shadow border rounded items-center">
                        <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                        <FaIcons.FaSearch color="#FF6600" />
                    </div>
                </div>
                <div className="mt-2 bg-red-100 p-1 rounded-md">

                    <ArhielTable
                        data={dataTable()}
                        state={[data, dispatch]}
                        initLoading={props.initLoading}
                        deleteHandler={deleteHandler}
                        editHandler={editHandler}
                        navrow={true} />

                    <ArhielTableNav page={[page, setpage]} jumlahPage={getJumlahPage()} />
                </div>

            </div>
        </>
    )
}

export default TablePage
