import React, {useState} from 'react'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";



const GetFaktur = props => {

    const [isLoading, setisLoading] = props.loading
    const klikHandler = () => {
        setisLoading(true)

    }

    return (
        <div className="flex w-full m-0 md:w-90">
            {
                isLoading ?
                <div className="p-1"><Dots color="#FF0000" /></div>
                :
                <>
                <button onClick={props.getHandler}  className="bg-red-400 ml-2 rounded-sm shadow-sm hover:bg-red-700 text-white py-2 px-2 text-xs font-bold">Refresh Nomor Faktur</button>
                <label className="text-xs ml-2 shadow border rounded flex-1 py-2 px-3 bg-gray-100 leading-tight">{props.value}</label>
                </>
            }
        </div>
    )
}

export default GetFaktur
