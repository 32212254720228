import React, { useReducer, useState, useEffect } from 'react'
import Context from '../Context'
import TablePage from '../../arhiel/pages/TablePage'
import Loading from '../../arhiel/components/Loading'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../variable';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            // console.log( " asddsa", action.data)
            return [...state, action.data]
        case 'delete':
            // console.log(action.data)
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        case 'edit':
            state[action.index] = action.data;

            return state;
        case 'init':

            return action.data
        default:
            return state;
    }
}

const DataBarang = () => {
    const field = [
        {
            title: 'Kode Barang',
            type: 'text',
            name: 'kodeBarang',
            require : true
        },
        {
            title: 'Nama Barang',
            type: 'text',
            name: 'namaBarang',
            require : true
        },
        {
            title: 'Satuan Barang',
            type: 'select',
            item: [
                {

                    title: "Dos",
                    value: "Dos",
                },
                {

                    title: "Drum",
                    value: "Drum",
                }
            ],
            name: 'satuanBarang',
            require : true
        }
    ]

    const [data, dispatch] = useReducer(reducer, []);
    const [initLoading, setInitLoading] = useState(true)
    useEffect(() => {
        setInitLoading(true)
        axios.get(SERVER + '/barang', {withCredentials:true}).then(res => {
            setInitLoading(false)
            dispatch({ type: 'init', data: res.data })
        }).catch(err => {
            console.log(err)
        })

    }, [])
    return (
        <Context>
            <TablePage
                link="/barang"
                judul="Daftar Barang"
                data={[data, dispatch]}
                initLoading={initLoading}

                show={10}
                field={field} />
        </Context>
    )
}

export default DataBarang
