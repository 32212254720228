import React from 'react'
import DatePicker from 'react-date-picker'
import * as FaIcons from "react-icons/fa"; 
const ArhielDatePicker = props => {
    const [value, setValue] = props.value
    
    return (
        <DatePicker
            onChange={value => setValue(value)}
            className="shadow text-sm border rounded w-full py-2 px-3 text-gray-700 "
            value={value}
            clearIcon={null}
            name="Arhiel"
            dayPlaceholder="01"
            minDate={props.minDate}
            disabled={props.disabled}
            
            monthPlaceholder="01"
            yearPlaceholder="2021"
            calendarIcon={<FaIcons.FaCalendar color="#FF0000" />}
            format="dd-MM-yyyy"
        />
    )
}

export default ArhielDatePicker
