import React, { useState, useReducer, useEffect } from 'react'
import ArhielLabel from '../../../arhiel/components/form/ArhielLabel'
import JudulPage from '../../../arhiel/components/JudulPage'
import Context from '../../Context'
import { Rp } from '../../../function/Rupiah'

import { useParams, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable'
import ArhielValue from '../../../arhiel/components/form/ArhielValue'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable'
import { ArhielTgl } from '../../../function/ArhielTgl'
import Loading from '../../../arhiel/components/Loading'
import Confirm from '../../../arhiel/components/Confirm'



const DetailPembelian = props => {

    const [isLoading, setisLoading] = useState(true)
    const [data, setdata] = useState([])

    const history = useNavigate();
    const editHandler = () => {
        history.push("/oli/pembelian/edit/" + params.id)
    }


    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", "Kode Barang", "Nama Barang", "Jumlah Barang", "Harga Satuan", "Jumlah Pembayaran", "PPN 10%", "Total Bayar"
        ]
        const value = [];
        if (data.barang) {
            data.barang.map((item, index) => {
                const isivalue = []
                isivalue.push({ value: index + 1, align: 'text-center' })
                isivalue.push({ value: item.kodeBarang, align: 'text-center' })
                isivalue.push({ value: item.namaBarang, align: 'text-left' })
                isivalue.push({ value: item.jumlahBarang, align: 'text-right' })
                isivalue.push({ value: Rp(item.hargaTotal / item.jumlahBarang, false), align: 'text-right' })
                isivalue.push({ value: Rp(item.hargaTotal, false), align: 'text-right' })
                isivalue.push({ value: Rp(item.ppn, false), align: 'text-right' })
                isivalue.push({ value: Rp(item.hargaTotal + item.ppn, false), align: 'text-right' })
                value.push({ key: item._id, value: isivalue })
                return false
            })
        }

        json['value'] = value;
        json['property'] = {

        }
        return json;
    }


    const [addForm, setaddForm] = useState(false)

    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="7" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total Jumlah Bayar</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getjumlah(), true)}</td>

            </tr>
        )
    }
    const getjumlah = () => {
        if (data.barang) {

            return data.barang.reduce((a, b) => {
                return a + (b['hargaTotal'] + b['ppn']);
            }, 0);
        } else {
            return 0
        }
    }


    const params = useParams()
    useEffect(() => {
        axios.get(SERVER + "/pembelian/" + props.objectId, { withCredentials: true }).then(res => {
            setisLoading(false)
            console.log(res.data[0])
            if (res.status === 200) {
                setdata(res.data[0])
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])


    const [confirmDelete, setconfirmDelete] = useState(false)
    const [modalLoading, setmodalLoading] = useState(false)
    const yesDeleteHandler = () => {
        setconfirmDelete(false)
        setmodalLoading(true)

        setmodalLoading(false)
        axios.delete(SERVER + "/pembelian/" + params.id, { withCredentials: true }).then(res => {

            setmodalLoading(false)
            const statusCode = res.status
            if (statusCode == 200) {
                history.push("/oli/pembelian/data/")


            } else {
                alert("Terjadi Massalah Error Code " + statusCode)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const deleteHandler = () => {

        setconfirmDelete(true)

    }

    //untuk pdf


    return (
        <>

            <Loading visible={[modalLoading, setmodalLoading]} />

            <Confirm
                visible={[confirmDelete, setconfirmDelete]}
                title="Hapus Data Pembelian?"
                titleyes="Hapus"
                yes={yesDeleteHandler} />
            <Context loading={isLoading}>

                <div className="md:flex">
                    <div className="md:flex-1 p-2">
                        <div className="mb-4 items-center border-b-2 border-solid border-gray-300 block md:flex">
                            <ArhielLabel>
                                Tanggal Transaksi
                            </ArhielLabel>
                            <ArhielValue>
                                {ArhielTgl(data.tglTransaksi)}
                            </ArhielValue>
                        </div>
                        <div className="mb-4 items-center border-b-2 border-solid border-gray-300 justify-between block md:flex">
                            <ArhielLabel>
                                Nama Penjual
                            </ArhielLabel>
                            <ArhielValue>{data.namaPenjual}</ArhielValue>
                        </div>
                        <div className="mb-4 items-center border-b-2 border-solid border-gray-300 justify-between block md:flex">
                            <ArhielLabel>
                                Kota
                            </ArhielLabel>
                            <ArhielValue>{data.kota}</ArhielValue>
                        </div>

                    </div>

                </div>
                <div className="bg-red-200 p-2 rounded-md shadow-md">
                    <ArhielTable
                        data={dataTable()}
                        addForm={[addForm, setaddForm]}
                        state={[data, () => { }]}
                        navrow={false}

                        footer={footerTable()}
                    />
                </div>

            </Context>
        </>
    )
}

export default DetailPembelian
