import React, { useState, useEffect } from 'react'
import ArhielLabel from '../arhiel/components/form/ArhielLabel'
import ArhielSelectWithModal from '../arhiel/components/form/ArhielSelectWithModal'
import ArhielTextBox from '../arhiel/components/form/ArhielTextBox'
import JudulPage from '../arhiel/components/JudulPage'
import { RpInput, rupiahBack } from '../function/Rupiah'
import { Rp } from '../function/Rupiah'

const AddBarangPembelian = props => {


    const [visible, setVisible] = props.visible
    const [keterangan, setketerangan] = useState("")
    const [data, dispatch] = props.data

    const [barang, setbarang] = useState({ id: 0, value: "" })
    const [jumlahBarang, setjumlahBarang] = useState(null)
    const [hargaTotal, sethargaTotal] = useState(null)
    const [ppn, setppn] = useState(null)

    const [isAdd, keySelected] = props.is


    useEffect(() => {

        if (visible) {
            if (!isAdd) {
                const dataselected = data.find(x => x.idBaris === keySelected)
                setbarang(
                    {
                        _id : dataselected._id,
                        title : dataselected.kodeBarang + " - " + dataselected.namaBarang,
                        value: {
                            _id: dataselected._id,
                            kodeBarang: dataselected.kodeBarang,
                            namaBarang: dataselected.namaBarang
                        }
                    })
                setketerangan("")
                setppn(dataselected.ppn)
                sethargaTotal(dataselected.hargaTotal)
                setjumlahBarang(dataselected.jumlahBarang)
            }
        } else {
            setbarang({ id: 0, value: "" })
            setjumlahBarang(null)
            setketerangan("")
            sethargaTotal(null)
            setppn(null)
        }



    }, [visible])

    const saveHandler = () => {


        // setShowModal(false)
        const last_id = Math.max(...data.map(item => item.idBaris), 0);
        if (barang.id === 0) {
            setketerangan("Pilih Barang Terlebih Dahulu")
        } else if (Number.isNaN(parseInt(jumlahBarang)) || parseInt(jumlahBarang) === 0) {
            setketerangan("Masukan Jumlah Barang")
        } else if (Number.isNaN(parseInt(hargaTotal)) || parseInt(hargaTotal) === 0) {
            setketerangan("Masukan Harga Satuan Barang")
        } else {
            setketerangan("")

            const newData = {
                idBaris : last_id + 1,
                ...barang.value,
                hargaTotal: parseInt(hargaTotal),
                jumlahBarang: parseInt(jumlahBarang),
                ppn: ppn
            }
            console.log(newData)
            if (isAdd) {


                dispatch({ type: 'add', data: newData })
            } else {
                const objIndex = data.findIndex(obj => obj.idBaris === keySelected);
                console.log(objIndex)
                dispatch({ type: 'edit', data: newData, index: objIndex })
            }
            setVisible(false)


        }
    }


    const hargaHandler = v =>{
        setppn(parseInt(rupiahBack(v.target.value)) * 10 / 100)
        sethargaTotal(rupiahBack(v.target.value))
    }

    const getDataBarang = () => {
        const dataBarang = []
        props.dataBarang.map((item, index) => {
            dataBarang.push({

                _id: item._id,
                title: `${item.kodeBarang} -  ${item.namaBarang}`,
                value: {
                    _id: item._id,
                    kodeBarang: item.kodeBarang,
                    namaBarang: item.namaBarang
                }

            })
        })
        return dataBarang;
    }

    return (
        <>

            <div className="w-full">
                <JudulPage>Tambah Data Baru</JudulPage>
                <div className="md:flex bg-white">
                    <div className="flex-1 p-2">
                        <div className="mb-4">

                            <ArhielLabel>Pilih Barang</ArhielLabel>
                            <ArhielSelectWithModal
                                data={getDataBarang()}
                                value={barang.title}
                                onSelect={item => setbarang(item)} />
                        </div>
                        <div className="mb-4">

                            <ArhielLabel>Jumlah Barang</ArhielLabel>
                            <ArhielTextBox value={jumlahBarang} onChange={v => setjumlahBarang(v.target.value)} />
                        </div>
                    </div>
                    <div className="flex-1 p-2">
                        <div className="mb-4">
                            <ArhielLabel>Total Harga</ArhielLabel>
                            <ArhielTextBox value={RpInput(hargaTotal + "")} onChange={hargaHandler} />
                        </div>
                        <div className="mb-4">
                            <ArhielLabel>PPN</ArhielLabel>
                            <div className="flex items-center">
                                <input value={Rp(ppn || 0, true)} disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline" type="text" />
                                
                            </div>
                        </div>
                    </div>
                </div>
                {
                    keterangan ?
                        <div className={"bg-red-400 p-2"}>
                            <span className="text-white block font-bold text-sm text-center justify-center items-center">{keterangan}</span>
                        </div>
                        : null
                }

                <div className="flex  items-center justify-center border-t-2 border-solid border-gray-300 pt-2">
                    <button onClick={saveHandler} className="bg-blue-500 mr-1 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded">Simpan</button>

                    <span onClick={() => setVisible(false)} className="text-xs font-bold ml-3 cursor-pointer text-red-800 active:text-red-500">Batal</span>
                </div>
            </div>

        </>

    )
}

export default AddBarangPembelian
