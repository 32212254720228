import React from 'react'
import ModalCover from '../../ModalCover'

const AddNewDT = props => {
    const [visible, setVisible] = props.visible
    return (
        <ModalCover  visible={visible} width="1/2" bg="white">
            {props.children}
        </ModalCover>
    )
}

export default AddNewDT
