import React, { useState } from 'react'
import * as FaIcons from "react-icons/fa";

const ArhielTableNav = props => {

    const [selectedValue, setselectedValue] = props.page
    const batas = props.jumlahPage;

    const createList = () => {
        const nomor = []
        for (let i = 1; i <= batas; i++) {
            nomor.push(
                <div
                    onClick={() => setselectedValue(i)}
                    className={"hover:bg-red-200 p-1 w-8 md:flex justify-center items-center  cursor-pointer leading-5 transition duration-150 ease-in rounded-full " + (selectedValue === i ? "bg-red-600 text-white flex" : "hidden")}>
                    {i}
                </div>
            )

        }
        return nomor
    }

    const nextHandler = () => {
        if(selectedValue < batas)
        {
            console.log(selectedValue + " " + batas)
            setselectedValue(selectedValue + 1)
        }
    }
    const prevHandler = () =>{
        if(selectedValue != 1 )
        {

            setselectedValue(selectedValue - 1)
        }

    }

    return (
        <div>

            <div className="flex flex-col items-center my-3">
                <div className="flex text-gray-700">
                    <div onClick={prevHandler} className={"p-4 mr-1 flex hover:bg-red-300 active:bg-red-600 justify-center items-center rounded-full bg-gray-200 cursor-pointer " + (selectedValue === 1 ? "opacity-0" : null)}>
                        <FaIcons.FaChevronLeft />
                    </div>
                    <div className="flex p-2 font-medium rounded-full bg-gray-200">
                        {createList()}
                        {/* <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">1</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">...</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">3</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  p-1 rounded-full bg-red-600 text-white ">4</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">5</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">...</div>
                        <div className="w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full  ">15</div>
                        <div className="w-8 h-8 md:hidden flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-red-600 text-white">4</div> */}
                    </div>
                    <div onClick={nextHandler} className={"p-4 mr-1 ml-1 hover:bg-red-300 active:bg-red-600 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer " + (selectedValue === batas ? "opacity-0" : null)}>
                        <FaIcons.FaChevronRight />
                    </div>
                </div>
            </div>



        </div>
    )
}

export default ArhielTableNav
