import React from 'react'

import { Dots } from "react-activity";

import "react-activity/dist/Dots.css";
const LoadingVerifikasi = () => {
    return (
        <div className=" flex justify-center items-center text-center absolute w-full bg-red-50 h-full">
            <div>
                <div className="p-1">
                <Dots color="#FF0000" />
            </div>
            <div className="text-red-900 font-bold mb-2">Mohon Tunggu...</div>
            <div className='text-xs font-bold'>Sedang Melakukan Pemeriksaan Keamanan</div>
            </div>
        </div>
    )
}

export default LoadingVerifikasi
