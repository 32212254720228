import React from 'react'
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import PDFTableBarang from './PDFTableBarang';

import logo from "../Assets/Images/logopertamina.png"
import { ArhielTglLessDay } from '../function/ArhielTgl';
import NomorFaktur from '../function/NomorFaktur';
import { Rp } from '../function/Rupiah';
const PDFTable = props => {
    const data = props.data;
    const label = props.label;

    const batas = 30;
    const bariskosong = jumlah => {
        const res = []
        for (let a = jumlah; a > 0; a--) {
            const colom = [];

            for (let i = 0; i < label.length; i++) {
                let width = 5
                if (i !== 0) {
                    width = 100 / (label.length - 2);
                }
                if(label[i].value === "kota"){ width = 9};
                colom.push(<View style={[styles.td, { width: width + "%" }]}><Text>-</Text></View>)
            }
            res.push(<View style={styles.tr}>

                {colom}
            </View>)
        }
        return res;
    }

    const barisdata = () => {
        const res = []
        data.map((item, index) => {
            const colom = [];

            for (let i = 0; i < label.length; i++) {
                let width = 5
                if (i !== 0) {
                    width = 100 / (label.length - 2);
                }
                
                if(label[i].value === "kota"){ width = 9};
                let value = item[label[i].value];
                if (label[i].value === "totalBayar") {
                    value = Rp(item[label[i].value])
                }
                colom.push(<View style={[styles.td, { width: width + "%", textAlign: 'right' }]}><Text>{value}</Text></View>)
            }
            // label.map((colom, i)=>{
            //     colom.push(
            //         // <View style={[styles.td, { width: "10%" }]}><Text>{item.nomor}</Text></View>
            //     )
            // })

            res.push(<View style={styles.tr}>

                {colom}
            </View>)
        })

        return res;
    }
    return (
        <Document>
            <Page orientation="portrait" size="A4" style={styles.page}>
                <View style={{ alignItems: 'center', padding: 10 }}>
                    <Text style={{ fontSize: 16 }}>{props.namaData}</Text>
                    <Text style={{ fontSize: 12, marginTop: 5 }}>{props.judul}</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.thead}>
                        <View style={styles.tr}>
                            {
                                label.map((item, index) => {
                                    let width = 5
                                    if (index !== 0) {
                                        width = 100 / (label.length - 2);
                                    }
                                    if(item.value === "kota"){ width = 9};
                                    return <View style={[styles.th, { width: width + "%" }]}><Text style={styles.thtext}>{item.label}</Text></View>
                                })
                            }


                        </View>
                    </View>
                    <View style={styles.tbody}>
                        {barisdata()}
                        {batas > data.length ? bariskosong(batas - data.length) : null}

                    </View>
                    <View style={styles.tfoot}>


                    </View>
                </View>

            </Page>


        </Document>
    )
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
    },
    header: {

    },
    table: {
        margin: 10,
    },
    thead: {
    },
    tr: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center',
        fontStyle: 'bold',
        borderTopColor: '#333333',
        borderTopWidth: 0.5,
        flexGrow: 1,
    },
    th: {
        fontSize: 9,
        padding: 5,
        borderLeftWidth: 0.5,
        borderColor: '#333333',
        flexDirection: 'row',
        fontFamily: "Helvetica-Bold",
        height: '100%',
        backgroundColor: '#EEEEEE',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    thtext: {
        fontFamily: "Helvetica-Bold",
        fontWeight: 'ultrabold',
    },
    tbody: {

    },
    td: {
        fontSize: 8,
        padding: 5,
        borderLeftWidth: 0.5,
        borderColor: '#333333',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-end',
        alignContent: 'flex-end'
    },
    tfoot: {
        borderBottomWidth: 0.5,
        borderColor: '#333333',
    }
})
export default PDFTable
