import React, { useState, useEffect } from 'react'
import * as FaIcons from "react-icons/fa"; // disini diibuh dari {iconName} jadi * as FaIcons
import Loading from '../../Loading';
import TdDT from './TdDT';
import ThDT from './ThDT';
import TrDT from './TrDT';





const ArhielDinamicTable = props => {

    const titleHeader = props.data.title;
    const data = props.data.value;
    // const [state, dispatch] = props.state

    




    const column = (value, index) => {
        return (
            <td className={"text-gray-900 text-xs p-2 md:border md:border-grey-500 text-left block md:table-cell md:" + value.align}>
                <span className="text-xs inline-block w-1/3 text-gray-900 md:hidden font-bold ">{titleHeader[index]}</span>
                <span>{value.value}</span>
            </td>
        )
    }


    const columnLast = key => {
        return <TdDT nowrap={true}>

            <button onClick={() => props.editHandler(key)} className="bg-blue-500 mr-1 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded">
                <span className="flex items-center"><FaIcons.FaEdit size={12} color="#FFFFFF" className="mr-1" /> Edit</span>

            </button>
            <button onClick={() => props.deleteHandler(key)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">
                <span className="flex items-center"><FaIcons.FaTrash size={12} color="#FFFFFF" className="mr-1" />Hapus</span>
            </button>

        </TdDT>
    }
    const rowKosong = () => {
        return (
            <TrDT>

                <td className="text-center text-xs text-gray-500 p-2 font-bold italic" colSpan={props.data.title.length + 1}>Data Belum Di Masukan</td>
            </TrDT>)
    }
    return (
        <>

            <button onClick={props.addHandler} className="mb-2 bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                <FaIcons.FaPlus className="mr-1" />
                <span>Tambah Barang</span>
            </button>
            <table className="min-w-full border-collapse block md:table">
                <thead className="block md:table-header-group">
                    <tr className="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative">
                        {
                            titleHeader.map((val, i) => {
                                return <ThDT>{val}</ThDT>

                            })

                        }
                        {
                            props.navrow ? <ThDT></ThDT> : null
                        }
                    </tr>

                </thead>
                <tbody className="block md:table-row-group">
                    {data.map((v, i) => {
                        return <TrDT baris={i}>
                            {
                                v.value.map((v, i) => {

                                    return column(v, i)
                                })
                            }
                            {props.navrow ?
                                columnLast(v.key) : null
                            }
                        </TrDT>
                    })}
                    {data.length === 0 ? rowKosong() : null}

                </tbody>
                <tfoot className="block md:table-row-group">
                    {props.footer}
                </tfoot>
            </table>
        </>
    )
}

export default ArhielDinamicTable
