import React from 'react'
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import PDFTableBarang from './PDFTableBarang';

import logo from "../Assets/Images/logopertamina.png"
import { ArhielTglLessDay } from '../function/ArhielTgl';
import NomorFaktur from '../function/NomorFaktur';
const PDFKwitansi = props => {
    const data = props.data;
    return (
        <Document>
            <Page orientation="portrait" size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10, }}>

                        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 18, color: '#0058a7', fontWeight: 'bold' }}>PT. Fajar Indah Kusuma</Text>

                        <View style={{ alignItems: 'center', fontSize: 10 }}>
                            <Text style={{textTransform:'capitalize'}}>{data.kota}, {ArhielTglLessDay(data.tglTransaksi)}</Text>
                            <Text style={{marginTop:5}}>Kepada </Text>
                            <Text style={{marginTop:5}}>Yth. {data.dataPelanggan.namaPelanggan}</Text>
                            <Text style={{marginTop:5}}>Di Tempat</Text>
                        </View>
                    </View>

                </View>
                <View style={{ paddingLeft: 10 }}>
                    <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 10 }}>Nomor Faktur : {NomorFaktur(data.nomorFaktur, data.tglTransaksi)}</Text>
                </View>
                <View>
                    <PDFTableBarang dataBarang={data.barang} />
                </View>
                <View style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, flexDirection: 'row', justifyContent: 'space-around', fontSize: 8 }}>
                    <View style={{ width: "20%" }}>
                        <Text style={{ fontFamily: "Helvetica" }}>Perhatian!!!</Text>
                        <Text style={{ fontFamily: "Helvetica-Bold", fontSize:7 }}>Barang yang sudah dibeli tidak dapat ditukar atau dikembalikan, {"\n"}kecuali ada perjanjian</Text>

                    </View>

                    <View style={{ textAlign: 'center', flex: 1 }}>
                        <Text>Hormat Kami</Text>
                        <View style={{ height: 30 }}></View>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>Kasir Pelumas</Text>
                    </View>
                    <View style={{ flex: 1, height: "100%", alignItems: 'center', flexDirection: 'row', textAlign: 'center', paddingRight: 20, justifyContent: 'center' }}>
                        <Text style={{ marginRight: 10 }}>Tanggal{"\n"}Jatuh Tempo</Text>
                        <Text style={{ marginRight: 10 }}>:</Text>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>{ArhielTglLessDay(data.tglJatuhTempo)}</Text>
                    </View>
                </View>
                <View style={{ paddingLeft: 10, fontSize: 6 }}>

                    <Text>Jln. Sultan Hasanuddin No. 19 Toli-Toli</Text>
                    <Text>Telp : 0453-21132/21027 Fax : 0453-22592</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop:10 }}>
                    <View style={{ flex: 1, height: '100%', justifyContent: 'center', borderTopRightRadius: 90, backgroundColor: '#ff5c5c', paddingLeft: 10, paddingRight: 30, paddingTop: 5, paddingBottom: 5 }}>
                        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, color: '#FFFFFF' }}>DISTRIBUTOR PELUMAS PERTAMINA</Text>
                    </View>
                    <Image style={{ width: 120, height: 20, marginBottom: 5, marginLeft: 10, marginRight:10 }} src={logo} />

                </View>
                
            </Page>


        </Document>
    )
}
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
    },
    section: {
        paddingHorizontal: 50,
        paddingVertical: 10

    }
});
export default PDFKwitansi
