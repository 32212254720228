import React from 'react'

const ThDT = props => {
    return (
        <th className="bg-red-600 p-2 text-xs text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">
            {props.children}
        </th>
    )
}

export default ThDT
