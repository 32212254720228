import React from 'react'

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Rp } from '../function/Rupiah';




const PDFTableBarang = props => {
   

    const data = props.dataBarang
    const batas = 8;
    const bariskosong = jumlah => {
        const res = []
        for (let i = jumlah; i > 0; i--) {
            res.push(<View style={styles.tr}>
                <View style={[styles.td, { width: "10%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "40%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>-</Text></View>
                <View style={[styles.td, { width: "10%", borderRightWidth:0.5 }]}><Text>-</Text></View>
            </View>)
        }
        return res;
    }
    const getjumlah = () => {
        if (data) {

            return data.reduce((a, b) => {
                return a + ((b['hargaSatuan'] * b['jumlahBarang']) - ((b['diskon'] / 100) * (b['hargaSatuan'] * b['jumlahBarang'])));
            }, 0);
        } else {
            return 0
        }
    }
    const barisbarang = () => {
        const res = []
        data.map((item, index)=>{
            res.push(<View style={styles.tr}>
                <View style={[styles.td, { width: "10%" }]}><Text>{index + 1}</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>{item.jumlahBarang + " " + item.satuanBarang}</Text></View>
                <View style={[styles.td, { width: "40%" }]}><Text>{item.namaBarang}</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>{Rp(item.hargaSatuan, false)}</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>{Rp(item.hargaSatuan * item.jumlahBarang, false)}</Text></View>
                <View style={[styles.td, { width: "10%" }]}><Text>{Rp(item.diskon / 100 * (item.jumlahBarang * item.hargaSatuan))}</Text></View>
                <View style={[styles.td, { width: "10%", borderRightWidth:0.5 }]}><Text>{Rp((item.hargaSatuan * item.jumlahBarang) - (item.diskon / 100 * (item.hargaSatuan * item.jumlahBarang)), false)}</Text></View>
            </View>)
        })
        
        return res;
    }

    return (
        <View style={styles.table}>
            <View style={styles.thead}>
                <View style={styles.tr}>
                    <View style={[styles.th, { width: "10%" }]}><Text style={styles.thtext}>Jumlah{"\n"}Barang</Text></View>
                    <View style={[styles.th, { width: "10%" }]}><Text style={styles.thtext}>Kode{"\n"}Barang</Text></View>
                    <View style={[styles.th, { width: "40%" }]}><Text style={styles.thtext}>Nama Barang</Text></View>
                    <View style={[styles.th, { width: "10%" }]}><Text style={styles.thtext}>Harga{"\n"}Satuan</Text></View>
                    <View style={[styles.th, { width: "10%" }]}><Text style={styles.thtext}>Jumlah{"\n"}Bayar</Text></View>
                    <View style={[styles.th, { width: "10%" }]}><Text style={styles.thtext}>Discount</Text></View>
                    <View style={[styles.th, { width: "10%", borderRightWidth:0.5 }]}><Text style={styles.thtext}>Total{"\n"}Bayar</Text></View>
                </View>
            </View>
            <View style={styles.tbody}>
                {barisbarang()}
                {bariskosong(batas - data.length)}
            </View>
            <View style={styles.tfoot}>
                <View style={styles.tr}>
                    <View style={[styles.td, { width: "60%" }]}></View>
                    <View style={[styles.td, { width: "30%", fontFamily:"Helvetica-Bold" }]}><Text style={styles.thtext}>Total Jumlah Bayar</Text></View>
                   
                    <View style={[styles.td, { width: "10%",fontFamily:"Helvetica-Bold", borderRightWidth:0.5 }]}><Text>{Rp(getjumlah())}</Text></View>
                </View>
                <View style={styles.tr}>
                    <View style={[styles.td, { width: "60%" }]}></View>
                    <View style={[styles.td, { width: "30%",fontFamily:"Helvetica-Bold" }]}><Text style={styles.thtext}>Dibulatkan</Text></View>
                   
                    <View style={[styles.td, { width: "10%", borderRightWidth:0.5, fontFamily:"Helvetica-Bold", textAlign:'right' }]}><Text style={{ textAlign:'right' }}>{Rp(getjumlah())}</Text></View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {

    },
    table: {
        margin: 10,
    },
    thead: {
    },
    tr: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center',
        fontStyle: 'bold',
        borderTopColor:'#333333',
        borderTopWidth:0.5,
        flexGrow: 1,
    },
    th: {
        fontSize: 9,
        padding: 5,
        borderLeftWidth: 0.5,
        textAlign: 'center',
        borderColor: '#333333',
        flexDirection: 'row',
        fontFamily:"Helvetica-Bold",
        height: '100%',
        backgroundColor: '#EEEEEE',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    thtext: {
        fontFamily:"Helvetica-Bold",
        fontWeight: 'ultrabold',
    },
    tbody: {

    },
    td: {
        fontSize: 8,
        padding: 5,
        borderLeftWidth: 0.5,
        borderColor: '#333333',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    tfoot:{
        borderBottomWidth: 0.5,
        borderColor: '#333333',
    }
})

export default PDFTableBarang
