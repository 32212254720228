import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { ipserver as SERVER } from '../variable';
axios.create({
    withCredentials: true
 })
const Login = () => {
    const navigate = useNavigate()
    const [state, dispatch] = useContext(AuthContext)
    
    const initialState = {
        username: "",
        password: "",
        isSubmiting: false,
        errorMessage: null,
    }

    const [data, setdata] = useState(initialState)

    const handleInputChange = event => {
        // console.log(event.target.name)
        setdata({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const coba = () => {
        axios.get("http://localhost:3300/dataadmin", { withCredentials: true }).then(res => {
            console.log(res.data)
        }).catch(error => {
            console.log(error.response.status)
            console.log(error.response.data)
        })
    }
    const handleSubmit = event => {
        event.preventDefault() // agar tidak reload saat button di klik. klo di PHP namanya return false

        if (data.username === "" || data.password === "") {
            alert("Masukan Username Dan Password Anda")
            return
        }

        setdata({
            ...data,
            isSubmiting: true,
            errorMessage: null,
        })

        const requestBody = {
            username: data.username,
            password: data.password
        }

        const config = {
            withCredentials: true
        }
        axios.post(SERVER + '/login', requestBody, config)
            .then(res => {
                setdata({
                    ...data,
                    isSubmiting: false
                })
                // console.log(res)
                dispatch({
                    type: "LOGIN",
                    payload: res.data
                })
                // console.log(res.data)
              

            }).catch(error => {
                setdata({
                    ...data,
                    isSubmiting: false
                })
                if (error.response) {
                    if (error.response.status === 401) {
                        alert("Username Atau Password Yang Anda Masukan Tidak Di Temukan")
                    } else {
                        alert("Terjadi Masalah Pada Jaringan Server")
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                }
            })
    }

    return (
        <div className="p-5 flex items-center justify-center h-screen bg-gray-300">
            <form className="bg-gray-100 w-full md:w-96 p-4 rounded-lg shadow-sm border-gray-400 border-2 border-solid" onSubmit={handleSubmit}>
                <div className="mx-auto">
                    <label className="w-full block mb-1 text-sm text-gray-600 font-bold" for="exampleEmail">Username</label>
                    <input className="bg-white focus:outline-none mb-2 py-1 px-2 text-sm w-full" type="text" value={data.username} onChange={handleInputChange} name="username" id="exampleEmail" placeholder="Masukan Username Anda" />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <label className="w-full block mb-1 text-sm text-gray-600 font-bold" for="examplePassword">Password</label>
                    <input className="bg-white focus:outline-none mb-2 py-1 px-2 text-sm w-full" type="password" value={data.password} onChange={handleInputChange} name="password" id="examplePassword" placeholder="Masukan Password Anda" />
                </div>
                <div className="alert alert-danger">{data.message}</div>
                <div className="flex items-center justify-between">
                    {
                        !data.isSubmiting ?
                            <>
                                <label onClick={() => { alert("Silahkan Hubungi Admin Untuk Mendapatkan Password") }} className="font-bold text-sm text-gray-500 hover:text-gray-800 cursor-pointer">Lupa Password ?</label>
                                <button disabled={data.isSubmiting} className="bg-red-700 rounded-sm hover:bg-red-500 text-sm text-white py-2 px-3 font-bold">
                                    Login
                                </button>
                            </>
                            :
                            <div className="flex  w-full items-center justify-center text-center">
                                Loading
                            </div>
                    }
                </div>

            </form>
            {/* <button onClick={coba} className="bg-red-700 rounded-sm hover:bg-red-500 text-sm text-white py-2 px-3 font-bold">
                Login
            </button> */}
        </div>
    )
}

export default Login
