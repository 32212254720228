import React, { Fragment, useState, useEffect } from 'react'

import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
const ArhielColomEditAble = props => {
    const [isLoading, setisLoading] = useState(false)
    const [edited, setedited] = useState(false)
    const [value, setvalue] = useState(props.value)
    
    const savehandler = () => {
        props.onChange(props.datakey, value, setisLoading, setedited)
    }
    useEffect(() => {
        setvalue(props.value)
    }, [props.value])

    return (
        <span>
            {
                isLoading ?
                <Dots color="#FF0000" />
                :
                edited ?
                    <Fragment>
                        <input className="px-2 rounded-md outline-none border-solid border-2 border-gray-500 text-xs py-1 mr-1" value={value} onChange={(v) => setvalue(v.target.value)} placeholder="Masukan Faktur Penjualan" />
                        <button className="bg-red-600 text-white px-2 py-1 font-bold rounded-md" onClick={savehandler}>Simpan</button>
                    </Fragment>
                    :
                    <Fragment>

                        {value}
                        <label
                            className="float-right text-red-900 font-bold italic cursor-pointer hover:text-red-500"
                            onClick={() => setedited(!edited)}>Edit</label>
                    </Fragment>
            }

        </span>
    )
}

export default ArhielColomEditAble
