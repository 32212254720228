import React, { useState, useEffect } from 'react'
import ModalCover from '../ModalCover'

import axios, * as others from 'axios';

import { ipserver as SERVER } from '../../../variable';

const ModalEdit = props => {
    const [visible, setvisible] = props.visible
    const filed = props.field;

    const [keterangan, setketerangan] = useState('')
    // const [arhiel, setarhiel] = useState(true)

    const [data, dispatch] = props.dispatch
    const dataSelected = data.find(a => a._id === props.selected)

    const [tempData, settempData] = useState({})
    const link = props.link

    useEffect(() => {
        console.log(dataSelected)
        if (dataSelected) {
            const data = {}
            filed.map((item, index) => {
                data[item.name] = dataSelected[item.name]
            })
            settempData(data)

        }
    }, [dataSelected])



    const saveHandler = async () => {
        // console.log(tempData)
        setketerangan("")
        let result = true;
        for (let i = 0; i < filed.length; i++) {
            // updateket()
            const val = tempData[filed[i].name]
            if (filed[i].require && (!val || val === "")) {

                setketerangan("Masukan " + filed[i].title + " Dengan Benar")
                result = false;
                break;
            }

        }

        if (result) {

            setvisible(false)
            props.loading(true)
            axios.put(SERVER + link + '/' + props.selected, tempData, { withCredentials: true }).then(res => {
                console.log(res.data)
                const statusCode = res.status
                if (statusCode == 200) {

                    const objIndex = data.findIndex(obj => obj._id === props.selected);
                    dispatch({ type: 'edit', index: objIndex, data: { ...tempData, _id: props.selected } })
                    // const objIndex = data.findIndex(obj => obj._id === props.selected);
                    // dispatch({type:'edit', index : objIndex, data : {...tempData, _id: props.selected}})
                } else {
                    alert("Terjadi Massalah Error Code " + statusCode)
                }

                props.loading(false)
            }).catch(err => {
                console.log(err)
            })

            // const objIndex = data.findIndex(obj => obj._id === props.selected);
            // dispatch({type:'edit', index : objIndex, data : {...tempData, _id: props.selected}})
            // setvisible(false)
            // console.log({...tempData, _id: props.selected})

            // setvisible(false)
        }
    }
    const changeHandler = (val) => {

        // console.log(val)
        setketerangan("")
        // const result = tempData;
        // result[val.target.name] = val.target.value
        settempData({
            ...tempData,
            [val.target.name]: val.target.value,
        })
        // console.log(result)
    }
    const buatinput = (type, nama, title, item, spesial) => {

        switch (type) {
            case "text":
                if (spesial === "npwp") {
                    return (
                        <>
                            <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                            <button onClick={() => settempData({
                                ...tempData,
                                [nama]: "000000000000000",
                            })} className="bg-red-500 mr-1 text-xs hover:bg-red-700 text-white italic py-1 px-2 border border-red-500 rounded">Klik Disini Jika Kosong</button>

                        </>)
                } else if (spesial === "nik") {
                    return (
                        <>
                            <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                            <button onClick={() => settempData({
                                ...tempData,
                                [nama]: "0000000000000000",
                            })} className="bg-red-500 mr-1 text-xs hover:bg-red-700 text-white italic py-1 px-2 border border-red-500 rounded">Klik Disini Jika Kosong</button>

                        </>)
                } else {

                    return <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder={title} />
                }
            case "password":
                return <input value={tempData[nama]} onChange={(val) => changeHandler(val)} name={nama} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" placeholder={title} />
            case "select":
                const resitem = [];
                item.map((value, index) => {
                    resitem.push(<option value={value.value}>{value.title}</option>)
                })
                return <select name={nama} value={tempData[nama]} onChange={(val) => changeHandler(val)} className="text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option disabled selected value="" className="font-bold italic text-xs">Pilih {title}</option>
                    {resitem}
                </select>
            default:

                break;
        }
    }
    return (
        <ModalCover visible={visible} width="1/3" bg="white" judul="Edit Data">
            <div>
                {
                    filed.map((item, index) => {

                        return <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                {item.title}
                            </label>
                            {
                                buatinput(item.type, item.name, item.title, item.item, item.spesial)
                            }
                        </div>
                    })
                }
            </div>

            {keterangan ?

                <div className={"bg-red-400 flex items-center justify-center align-middle rounded-md"}>
                    <label className="text-white font-bold m-1 text-xs">{keterangan}</label>
                </div> : null

            }

            {/* {
                arhiel ?
                <div>aaaa</div> : null
            } */}
            <div className="flex  items-center justify-center pt-1">
                <button onClick={saveHandler} className="bg-red-500 mr-1 text-sm hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">Simpan</button>

                <span onClick={() => setvisible(false)} className="text-xs font-bold ml-3 cursor-pointer text-red-800 active:text-red-500">Batal</span>
            </div>
        </ModalCover >
    )
}

export default ModalEdit
