import React from 'react'

const TrDT = props => {
    return (
        <tr className={"border border-grey-500 md:border-none block md:table-row " + (parseInt(props.baris) % 2 ? " bg-gray-50" : "bg-gray-100")}>
            {props.children}    
        </tr>
    )
}

export default TrDT
