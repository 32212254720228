import React, { useState } from 'react'
import * as FaIcons from "react-icons/fa"; // disini diibuh dari {iconName} jadi * as FaIcons

import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ArhielColomEditAble from './ArhielColomEditAble';

const ArhielTable = props => {

    const titleHeader = props.data.title;
    const data = props.data.value;

    const [state, dispatch] = props.state;



    const column = (value, index, key) => {
        
        return (
            <td className={"text-gray-900 text-xs p-2 md:border md:border-grey-500 text-left block md:table-cell md:" + value.align}>
                <span className="text-xs inline-block w-1/3 text-gray-900 md:hidden font-bold ">{titleHeader[index]}</span>
                
                {
                    value.editable ? 
                    
                    <ArhielColomEditAble onChange={value.onChange} value={value.value} datakey={key} />
                   
                    :
                    <span>{value.value}</span>
                    
                }
            </td>
        )
    }
    const detailHandler = key => {
       
        // setisiDetail(props.detailHandler(key))
        props.detailHandler(key)
    }
    const columnLast = key => {
        return <td nowrap className={"text-gray-900 text-xs p-2 md:border md:border-grey-500 text-left block md:table-cell md:text-right"}>
            {props.detailButton && key !== 0 ?
                <button onClick={() => detailHandler(key)} className="bg-green-500 mr-1 hover:bg-green-700 text-white font-bold py-1 px-2 border border-green-500 rounded">
                    <span className="flex items-center">
                        <FaIcons.FaDatabase size={12} color="#FFFFFF" className="mr-1" />
                        Detail
                    </span>

                </button>
                : null
            }
            {props.editHandler ?

                <button onClick={() => props.editHandler(key)} className="bg-blue-500 mr-1 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded">
                    <span className="flex items-center"><FaIcons.FaEdit size={12} color="#FFFFFF" className="mr-1" /> Edit</span>
                </button> : null
            }
            {
                props.deleteHandler ?

                    <button onClick={() => props.deleteHandler(key)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded">
                        <span className="flex items-center"><FaIcons.FaTrash size={12} color="#FFFFFF" className="mr-1" />Hapus</span>
                    </button> : null
            }

        </td>
    }
    const columnHead = (value) => {
        return (
            <th className="bg-red-600 p-2 text-xs text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">{value}</th>
        )
    }
    const row = (index, datakolom) => {

        return (
            <tr className={"border border-grey-500 hover:bg-red-100 md:border-none block md:table-row " + (parseInt(index) % 2 ? " bg-gray-50" : "bg-gray-100")}>
                {

                    datakolom.value.map((v, i) => {
                        // console.log("arhieo", v)
                        return column(v, i, datakolom.key)
                    })

                }
                {props.navrow ?
                    columnLast(datakolom.key) : null
                }
            </tr>)
    }

    const rowKosong = () => {
        return (
            <tr className={"border border-grey-500 md:border-none block md:table-row bg-gray-50"}>

                <td className="text-center text-xs text-gray-500 p-2 font-bold italic" colSpan={props.data.title.length + 1}>Data Tidak Di Temukan</td>
            </tr>)
    }
    return (
        <>
            <table className="min-w-full border-collapse block md:table">
                <thead className="block md:table-header-group">
                    <tr className="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative">
                        {
                            titleHeader.map((val, i) => {
                                return columnHead(val)

                            })

                        }
                        {
                            props.navrow ? columnHead("") : null
                        }
                    </tr>

                </thead>
                <tbody className="block md:table-row-group">
                    {
                        props.initLoading ?

                            <tr className="block md:table-row">
                                <td
                                    colSpan={titleHeader.length + 1}
                                    className="bg-white p-3 align-middle text-center block md:table-cell">
                                    <Dots color="#FF0000" />
                                    <span className="text-red-900 text-sm font-bold mt-2 block">Loading Data</span>
                                </td>
                            </tr>
                            :
                            <>
                                {data.map((v, i) => {
                                    // console.log("baris", v)
                                    return row(i, v)
                                })}
                                {data.length === 0 ? rowKosong() : null}
                            </>


                    }
                </tbody>

                <tfoot className="block md:table-row-group">
                    {props.footer}
                </tfoot>
            </table>
        </>
    )
}

export default ArhielTable
