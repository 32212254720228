import React, { useReducer, useEffect, useState } from 'react'
import ArhielTable from '../../../arhiel/components/TablePage/ArhielTable';
import Context from '../../Context';
import { useNavigate, useParams } from "react-router-dom";
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable';
import JudulPage from '../../../arhiel/components/JudulPage';
import ArhielNavigasi from '../../../arhiel/components/ArhielNavigasi';
import ArhielTableNav from '../../../arhiel/components/TablePage/ArhielTableNav';
import JudulTable from '../../../arhiel/components/JudulTable';

import * as FaIcons from "react-icons/fa";
import { NamaBulan } from '../../../function/ArhielMonth';

import DownloadExcel from '../../../arhiel/components/DownloadExcel';
import PDFTable from '../../../components/PDFTable';
import ArhielPDFView from '../../../arhiel/components/ArhielPDFView';
import { ArhielTgl } from '../../../function/ArhielTgl';
import { Rp } from '../../../function/Rupiah';

const reducer = (state, action) => {
    switch (action.type) {
        case "init":
            return action.data
        case 'delete':
            const newstate = state.filter(a => a._id !== action.data)
            return newstate;
        default:
            return state;
    }
}

const DataArusKas = props => {

    const params = useParams()
    const [data, dispatch] = useReducer(reducer, [])
    const [loadingVisible, setloadingVisible] = useState(true)


    const [typeData, settypeData] = useState("bulanan")
    const [valueData, setvalueData] = useState({ bulan: new Date().getMonth() + 1, tahun: new Date().getFullYear() })



    useEffect(() => {


        setloadingVisible(false)
        getData(typeData, valueData)
    }, [valueData, params.kota])


    const [judulTable, setjudulTable] = useState("")
    const getData = (type, value) => {
        setloadingVisible(true)
        setsearchText("")

        const link = `${value.bulan}/${value.tahun}`
        console.log(link)
        // alert(SERVER + "oli/penjualan/" +link)
        // alert(SERVER + "/kas/toli-toli/" + valueData.bulan + "/" + valueData.tahun)
        axios.get(SERVER + "/kas/"+params.kota+"/" + valueData.bulan + "/" + valueData.tahun, {withCredentials:true}).then((res) => {
            console.log(res.data)
            setjudulTable(<><p className="capitalize">Data Kas {params.kota} </p><p>Bulan {NamaBulan[value.bulan - 1]} {value.tahun}</p></>)

            setloadingVisible(false)
            setnamaFileExport("Data Kas Bulan " + NamaBulan[value.bulan - 1] + " " + value.tahun)
            setprintjudulTable("Bulan " + NamaBulan[value.bulan - 1] + " " + value.tahun)
            dispatch({ type: 'init', data: res.data })
            // console.log(res.data)
        })
    }

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", 'Tanggal', "Penjualan", "Pembelian"
        ]
        const value = [];

        var endmonth = new Date(Date.UTC(valueData.tahun, valueData.bulan, 0));
        var firstmonth = new Date(Date.UTC(valueData.tahun, valueData.bulan - 1, 1));

        let nomor = 1;
        let saldo = 0;
        if (data.datas) {

            for (var d = firstmonth; d <= endmonth; d.setDate(d.getDate() + 1)) {
                const isivalue = []


                const getPenjualan = data.datas.find(x => new Date(d).getDate() === new Date(x._id).getDate() && x.type === "penjualan")
                const penjualan = getPenjualan ? getPenjualan.totalBayar : 0;
                const getPembelian = data.datas.find(x => new Date(d).getDate() === new Date(x._id).getDate() && x.type === "pembelian")
                const pembelian = getPembelian ? getPembelian.totalBayar : 0;

                saldo = saldo + penjualan - pembelian;

                isivalue.push({ value: nomor, align: 'text-center' })
                isivalue.push({ value: ArhielTgl(new Date(d)), align: 'text-left' })
                isivalue.push({ value: Rp(penjualan), align: 'text-right' })
                isivalue.push({ value: Rp(pembelian), align: 'text-right' })
                value.push({ key: new Date(d), value: isivalue })


                nomor++;
            }
        }


        json['value'] = value;
        json['property'] = {

        }
        return json;
    }
    const history = useNavigate();
    const detailHandler = (key) => {

        const link = key.getFullYear() + "-" + (key.getMonth() + 1) + "-" + key.getDate()
        // alert(link)
        history.push("/oli/kas/" + link)
    }




    //untuk masalah page


    const [searchText, setsearchText] = useState("")


    //untuk masalah print dan export
    const [namaFileExport, setnamaFileExport] = useState("")
    const headerExel = [

        {
            label: "No",
            value: "nomor",
        },
        {
            label: "Tanggal",
            value: "tglTransaksi",
        },
        {
            label: "Penjualan",
            value: "penjualan",
        },
        {
            label: "Pembelian",
            value: "pembelian",
        },
    ]

    const isiExcel = () => {
        const result = [];

        var endmonth = new Date(Date.UTC(valueData.tahun, valueData.bulan, 0));
        var firstmonth = new Date(Date.UTC(valueData.tahun, valueData.bulan - 1, 1));

        if (data.datas) {

            let nomor = 1;
            let saldo = 0;



            
            for (var d = firstmonth; d <= endmonth; d.setDate(d.getDate() + 1)) {

                const getPenjualan = data.datas.find(x => new Date(d).getDate() === new Date(x._id).getDate() && x.type === "penjualan")
                const penjualan = getPenjualan ? getPenjualan.totalBayar : 0;
                const getPembelian = data.datas.find(x => new Date(d).getDate() === new Date(x._id).getDate() && x.type === "pembelian")
                const pembelian = getPembelian ? getPembelian.totalBayar : 0;

                saldo = saldo + penjualan - pembelian;

                const isi = {}
                isi['nomor'] = nomor
                isi['tglTransaksi'] = ArhielTgl(d)
                isi['penjualan'] = Rp(penjualan)
                isi['pembelian'] = Rp(pembelian)
                result.push(isi)
                nomor++;
            }
            const isif = {}
            isif['nomor'] = "-"
            isif['tglTransaksi'] = "Total"
            isif['penjualan'] = Rp(getJumlahPenjualan())
            isif['pembelian'] = Rp(getjumlahPembelian())
            isif['saldoAkhir'] = "-"
            result.push(isif)
        }
        return result;
    }
    const [printjudulTable, setprintjudulTable] = useState("")
    const [pdfViewr, setpdfViewr] = useState(false)
    const pdfHandler = () => {
        setpdfViewr(true)

    }
    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="2" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getJumlahPenjualan(), true)}</td>
                <td className="border-grey-500 md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getjumlahPembelian(), true)}</td>
                <td colSpan="2" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right"></td>

            </tr>
        )
    }
    const getjumlahPembelian = () => {
        if (data.datas) {
            return data.datas.filter(({ type }) => type === 'pembelian')
                .reduce((a, b) => {
                    return a + b['totalBayar'];
                }, 0);
        } else {
            return 0
        }
    }
    const getJumlahPenjualan = () => {
        if (data.datas) {

            return data.datas.filter(({ type }) => type === 'penjualan')
                .reduce((a, b) => {
                    return a + b['totalBayar'];
                }, 0);
        } else {
            return 0
        }
    }
    return (
        <>
            <ArhielPDFView visible={[pdfViewr, setpdfViewr]}>
                <PDFTable
                    namaData={"Data Saldo"}
                    judul={printjudulTable}
                    label={headerExel}
                    data={isiExcel()} />
            </ArhielPDFView>
            <Context>

                <div className="bg-white overflow-visible min-h-full rounded-lg shadow-md mr-2 mb-4">
                    <JudulPage>
                        Data penjualan
                    </JudulPage>
                    <ArhielNavigasi disableType={true}
                        onClick={(type, value) => { settypeData(type); setvalueData(value); getData(type, value) }}
                    />

                    <div className="p-0 pb-2 min-h-full">
                        <div>
                            <JudulTable>{judulTable}</JudulTable>
                        </div>
                        <div className="md:flex justify-between bg-red-200 p-2 align-middle items-center">
                            <div className="w-full pb-2 md:pb-0 items-center justify-between flex md:items-start md:justify-start">
                                <DownloadExcel
                                    label={headerExel}
                                    fileName={namaFileExport}
                                    data={isiExcel()} />
                                <button onClick={pdfHandler} className=" bg-red-500 mr-1 text-xs flex flex-1 md:flex-none  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaFilePdf color="#FFFFFF" />
                                    <span className="ml-2">Print PDF</span>
                                </button>
                            </div>


                            <div className="flex flex-1 justify-between items-center">
                                <button onClick={() => getData(typeData, valueData)} className=" bg-red-500 mr-1 text-xs flex  items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                                    <FaIcons.FaSync className="mr-1" />
                                    <span>Refresh</span>
                                </button>
                                <div className="flex w-min py-1 px-2 bg-white shadow border rounded items-center">
                                    <input value={searchText} onChange={v => setsearchText(v.target.value)} className=" appearance-none text-gray-700 text-sm  focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Cari Data" />
                                    <FaIcons.FaSearch color="green" />
                                </div>
                            </div>
                        </div>
                        <ArhielTable
                            data={dataTable()}
                            state={[data, dispatch]}
                            navrow={true}
                            detailButton={false}
                            // detailHandler={detailHandler}
                            // editHandler={editHandler}
                            initLoading={loadingVisible}
                            footer={footerTable()}
                        />
                    </div>
                </div>
            </Context>
        </>
    )
}

export default DataArusKas
