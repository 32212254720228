import React, { useState, useReducer, useEffect } from 'react'
import ArhielLabel from '../../../arhiel/components/form/ArhielLabel'
import JudulPage from '../../../arhiel/components/JudulPage'
import Context from '../../Context'
import ArhielTextBox from '../../../arhiel/components/form/ArhielTextBox'
import ArhielDinamicTable from '../../../arhiel/components/form/dinamicTable/ArhielDinamicTable'
import AddBarang from '../../../components/AddBarang'
import { Rp } from '../../../function/Rupiah'
import AddNewDT from '../../../arhiel/components/form/dinamicTable/AddNewDT'
import * as FaIcons from "react-icons/fa";
import Confirm from '../../../arhiel/components/Confirm'
import Loading from '../../../arhiel/components/Loading'
import axios, * as others from 'axios';
import { ipserver as SERVER } from '../../../variable'
import NomorFaktur from '../../../function/NomorFaktur'
import ArhielValue from '../../../arhiel/components/form/ArhielValue'
import { ArhielTgl } from '../../../function/ArhielTgl'
import ArhielSelect from '../../../arhiel/components/form/ArhielSelect'

import ArhielDatePicker from '../../../arhiel/components/form/ArhielDatePicker'
import ArhielSelectWithModal from '../../../arhiel/components/form/ArhielSelectWithModal'


const reducer = (state, action) => {
    switch (action.type) {
        case 'delete':
            console.log(action.data)
            const newstate = state.filter(a => a.idBaris !== action.data)
            return newstate;
        case 'add':
            return [...state, action.data]
        case 'edit':
            state[action.index] = action.data;
            return state;
        case 'init':
            return action.data
        default:
            return state;
    }
}

const EditPenjualan = props => {

    const [isLoading, setisLoading] = useState(true)
    const [data, dispatch] = useReducer(reducer, []);
    const [tglTransaksi, settglTransaksi] = useState(new Date())
    const [namaPenjual, setnamaPenjual] = useState("")
    const [kota, setkota] = useState("")
    const [caraBayar, setcaraBayar] = useState("")
    const [tglJatuhTempo, settglJatuhTempo] = useState(new Date())
    const [pelanggan, setpelanggan] = useState({ _id: 0, value: "" })

    const [isAdd, setisAdd] = useState(false)

    const dataTable = () => {
        const json = {}
        json['title'] = [
            "No", "Kode Barang", "Nama Barang", "Harga Satuan", "Jumlah Barang", "Jumlah Pembayaran", "Discount", "Total Bayar"
        ]
        const value = [];
        data.map((item, index) => {
            const isivalue = []
            isivalue.push({ value: index + 1, align: 'text-center' })
            isivalue.push({ value: item.kodeBarang, align: 'text-center' })
            isivalue.push({ value: item.namaBarang, align: 'text-left' })
            isivalue.push({ value: Rp(item.hargaSatuan, false), align: 'text-right' })
            isivalue.push({ value: item.jumlahBarang, align: 'text-right' })
            isivalue.push({ value: Rp(item.hargaSatuan * item.jumlahBarang, false), align: 'text-right' })
            isivalue.push({ value: Rp(item.diskon / 100 * (item.jumlahBarang * item.hargaSatuan), false), align: 'text-right' })
            isivalue.push({ value: Rp((item.hargaSatuan * item.jumlahBarang) - (item.diskon / 100 * (item.hargaSatuan * item.jumlahBarang)), false), align: 'text-right' })
            value.push({ key: item.idBaris, value: isivalue })
            return false
        })
        json['value'] = value;
        json['property'] = {

        }
        return json;
    }


    const [addForm, setaddForm] = useState(false)

    const footerTable = () => {
        return (
            <tr className="flex justify-between w-full bg-red-600 mt-2 md:table-row">
                <td colSpan="7" className="border-grey-500 md:border text-left font-bold text-white p-2 text-sm flex-1 block md:table-cell md:text-right">Total Jumlah Bayar</td>
                <td className="border-grey-500 whitespace-nowrap md:border text-right p-2 font-bold text-white text-sm block md:table-cell">{Rp(getjumlah(), true)}</td>
                <td className="text-right p-2 hidden text-sm md:table-cell"></td>
            </tr>
        )
    }
    const getjumlah = () => {
        return data.reduce((a, b) => {
            return a + ((b['hargaSatuan'] * b['jumlahBarang']) - ((b['diskon'] / 100) * (b['hargaSatuan'] * b['jumlahBarang'])));
        }, 0);
    }

    const verifikasiData = () => {
        if (namaPenjual === "") {
            alert("Masukan Nama Penjual / Nama Toko Terlebih Dahulu")
        } else if (nomorFaktur === 0) {
            alert("Silahkan Klik Ambil Nomor Faktur Untuk Mendapatkan Nomor Faktur Terbaru")
        } else if (data.length === 0) {
            alert("Masukan Minimal 1 Data Barang")

        } else {
            setconfirmSave(true)
        }
    }

    const [loadingVisible, setloadingVisible] = useState(false)
    const [confirmSave, setconfirmSave] = useState(false)

    const saveHandler = () => {
        const dataBarang = data.map(({ namaBarang, kodeBarang, ...keepAttrs }) => keepAttrs)
        
        const tgltrx = new Date(Date.UTC(tglTransaksi.getFullYear(), tglTransaksi.getMonth(), tglTransaksi.getDate()));
        const tgljt = new Date(Date.UTC(tglJatuhTempo.getFullYear(), tglJatuhTempo.getMonth(), tglJatuhTempo.getDate()));
        
        
        const result = {
            // tglTransaksi: tglTrx,
            pelanggan: pelanggan._id,
            kota,
            tglJatuhTempo : (caraBayar === "hutang" ? tgljt : tgltrx),
            // nomorFaktur,
            dataBarang: dataBarang
        }
        setconfirmSave(false)
        setloadingVisible(true)
        // console.log(result)
        axios.put(SERVER + `/penjualan/` + props.objectId, result, {withCredentials:true})
            .then(res => {

                setloadingVisible(false)
                // console.log(res.data)
                const statusCode = res.status
                if (statusCode === 200) {
                    // history.push("/oli/penjualan/detail/" + props.match.params.id)
                    props.editFinish()
                } else {
                    alert("Error Code " + res.status)
                }
            })

    }

    const addHandler = () => {
        setaddForm(true)
        setisAdd(true)
    }
    const [confirmVisible, setconfirmVisible] = useState(false)
    const [keySelected, setkeySelected] = useState(0)
    const editHandler = key => {
        // console.log(data)
        setkeySelected(key)
        // console.log(data)
        setisAdd(false)
        setaddForm(true)
    }
    const deleteHandler = key => {
        setconfirmVisible(true)
        setkeySelected(key)
    }
    const yesHadler = () => {

        dispatch({ type: 'delete', data: keySelected })
    }


    //untuk get faktur
    const [nomorFaktur, setnomorFaktur] = useState(0)

    const [dataBarang, setdataBarang] = useState([])
    useEffect(() => {
        axios.get(SERVER + '/barang', {withCredentials:true}).then(res => {
            setisLoading(false)
            if (res.status === 200) {
                setdataBarang(res.data)
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
        axios.get(SERVER + "/penjualan/" + props.objectId, {withCredentials:true}).then(res => {
            console.log(res.data[0])
            if (res.status === 200) {
                dispatch({ type: 'init', data: res.data[0].barang })
                settglTransaksi(new Date(res.data[0].tglTransaksi))
                setnomorFaktur(res.data[0].nomorFaktur)
                setnamaPenjual(res.data[0].namaPenjual)
                res.data[0].tglJatuhTempo === res.data[0].tglTransaksi ? setcaraBayar('tunai') : setcaraBayar('hutang')
                settglJatuhTempo(new Date(res.data[0].tglJatuhTempo))
                setkota(res.data[0].kota)
                setpelanggan({
                    _id : res.data[0].dataPelanggan._id,
                    title : res.data[0].dataPelanggan.kodePelanggan + " - " + res.data[0].dataPelanggan.namaPelanggan,
                    value: {
                        _id: res.data[0].dataPelanggan._id,
                        kodeBarang: res.data[0].dataPelanggan.kodePelanggan,
                        namaBarang: res.data[0].dataPelanggan.namaPelanggan
                    }
                })
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
        axios.get(SERVER + '/pelanggan', {withCredentials:true}).then(res => {
            setisLoading(false)
            if (res.status === 200) {
                setdataPelanggan(res.data)
            } else {
                alert("Gagal Mengambil Data " + res.status)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const [dataPelanggan, setdataPelanggan] = useState([])
    const getDataPelanggan = () => {
        const dataBarang = []
        dataPelanggan.map((item, index) => {
            dataBarang.push({

                _id: item._id,
                title: `${item.kodePelanggan} -  ${item.namaPelanggan}`,
                value: {
                    _id: item._id,
                    kodePelanggan: item.kodePelanggan,
                    namaPelanggan: item.namaPelanggan
                }

            })
        })
        return dataBarang;
    }
    return (
        <Context loading={isLoading}>

            <AddNewDT visible={[addForm, setaddForm]}>
                <AddBarang dataBarang={dataBarang} is={[isAdd, keySelected]} visible={[addForm, setaddForm]} data={[data, dispatch]} />
            </AddNewDT>
            <Loading visible={[loadingVisible, setloadingVisible]} />

            <Confirm
                visible={[confirmVisible, setconfirmVisible]}
                title="Hapus Data Barang Ini ?"
                titleyes="Hapus"
                yes={yesHadler} />
            <Confirm
                visible={[confirmSave, setconfirmSave]}
                title="Apakah Data Yang Dimasukan Sudah Benar ?"
                titleyes="Simpan"
                yes={saveHandler} />
            <div className="md:flex">
                <div className="md:flex-1 p-2">
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Tanggal Transaksi
                        </ArhielLabel>
                        <ArhielValue>
                            {ArhielTgl(tglTransaksi)}
                            <span className="ml-2 text-red-300 text-xs italic font-bold"></span>

                        </ArhielValue>
                    </div>
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Nama Kota
                        </ArhielLabel>
                        <ArhielSelect value={kota} placeholder="Pilih Kota" onChange={v => setkota(v.target.value)}>
                            <option value={"toli-toli"}>Toli-Toli</option>
                            <option value={"buol"}>Buol</option>
                        </ArhielSelect>
                    </div>
                    <div className="mb-4 items-center block md:flex">
                        <ArhielLabel>
                            Nomor Faktur
                        </ArhielLabel>
                        <ArhielValue>
                            {NomorFaktur(nomorFaktur, tglTransaksi)}
                            <span className="ml-2 text-red-300 text-xs italic font-bold"> Nomor Faktur Tidak Dapat Di Ubah</span>
                        </ArhielValue>

                    </div>
                </div>
                <div className="md:flex-1 p-2">
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Pilih Pelanggan
                        </ArhielLabel>
                        <ArhielSelectWithModal
                            data={getDataPelanggan()}
                            value={pelanggan.title}
                            onSelect={item => setpelanggan(item)} />
                    </div>
                    <div className="mb-4 items-center justify-between block md:flex">
                        <ArhielLabel>
                            Cara Bayar
                        </ArhielLabel>
                        <ArhielSelect value={caraBayar} placeholder="Pilih Cara pembayaran" onChange={v => setcaraBayar(v.target.value)}>
                            <option value={"tunai"}>Tunai</option>
                            <option value={"hutang"}>Hutang</option>
                        </ArhielSelect>
                    </div>
                    {
                        caraBayar === "hutang" ?
                            <div className="mb-4  items-center justify-between block md:flex">
                                <ArhielLabel>
                                    Jatuh Tempo
                                </ArhielLabel>
                                <ArhielDatePicker minDate={tglTransaksi} value={[tglJatuhTempo, settglJatuhTempo]} />
                            </div>
                            : null
                    }
                </div>

            </div>
            <div className="bg-red-200 p-2 rounded-md shadow-md">
                <ArhielDinamicTable
                    data={dataTable()}
                    addForm={[addForm, setaddForm]}
                    state={[data, dispatch]}
                    navrow={true}
                    addHandler={addHandler}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    footer={footerTable()} />
            </div>
            <div className="border-t-2 border-solid border-gray-200 mt-2 p-2 flex justify-end items-center">
                <span className="mr-2 text-sm font-bold text-gray-500 italic">Harap Pastikan Seluruh Data Terisi Dengan Benar</span>
                <button onClick={verifikasiData} className=" bg-red-500 mr-1 text-xs flex items-center hover:bg-red-700 text-white font-bold py-2 px-2 border border-red-500 rounded">
                    <FaIcons.FaSave className="mr-1" />
                    <span>Update</span>
                </button>
            </div>
        </Context>
    )
}

export default EditPenjualan
